<template>
  <TabsPageHeader
    :tabs="tabs"
    :search="!$isMobile"
    :search-keyword.sync="searchKeyword"
    :search-placeholder="$t('tasks.searchPlaceholder')"
  >
    <template #actions>
      <ProjectTasksViewOptions
        v-if="!$isMobile"
        class="mr-8"
        :value="viewContext"
        :hidden="['publicTasks', 'privateTasks']"
        @input="val => $emit('update:view-context', val)"
      />
    </template>
  </TabsPageHeader>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import {
  PORTAL_TASKS_KANBAN,
  PORTAL_TASKS_LIST,
  PORTAL_TASKS_DOCUMENTS,
} from '@/client-portal/router/types'
import TabsPageHeader from '@/components/TabsPageHeader.vue'
import ProjectTasksViewOptions from '@/components/ProjectTasksViewOptions.vue'

const TABS = [
  {
    id: 'list',
    translationKey: 'tasks.list',
    icon: 'icon_v2-so_list-view',
    to: { name: PORTAL_TASKS_LIST },
    activeOnChildren: true,
  },
  {
    id: 'kanban',
    translationKey: 'tasks.board',
    icon: 'icon_v2-so_grid',
    to: { name: PORTAL_TASKS_KANBAN },
    activeOnChildren: true,
  },
  {
    id: 'documents',
    translationKey: 'modules.docs',
    icon: 'icon_v2-so_docs-files',
    to: { name: PORTAL_TASKS_DOCUMENTS },
  },
]

export default defineComponent({
  name: 'ClientPortalTasksPageHeader',
  components: {
    TabsPageHeader,
    ProjectTasksViewOptions,
  },
  props: {
    viewContext: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      default: '',
    },
    hiddenTabs: {
      type: Array,
      default: () => [],
    },
    hideNewSectionButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const searchKeyword = computed({
      get() {
        return props.keyword
      },
      set(val) {
        context.emit('update:keyword', val)
      },
    })

    const tabs = computed(
      () => TABS.filter(tab => !props.hiddenTabs.includes(tab.id))
    );

    return {
      tabs,
      searchKeyword,
    }
  },
})
</script>
