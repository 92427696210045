<template>
  <portal to="app-sidebar-right">
    <div
      ref="editor"
      class="
        grid max-w-[100vw] w-[540px] bg-white/90 backdrop-blur-sm
        dark:bg-darkGray-800 dark:border-darkGray-700
        shadow-xs p-40 h-full
      "
    >
      <ProjectTaskEditor
        :project="project"
        :section-id="sectionId"
        :document-id="documentId"
        :task-id="taskId"
        :disabled-fields="disabledFields"
        restrict-to-owner
        hide-portal-visibility
        @close="close"
        @created="task => $emit('created', sectionId, task)"
        @updated="task => $emit('updated', task)"
        @remove="task => $emit('remove', task)"
      />
    </div>
  </portal>
</template>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { onClickOutside } from '@vueuse/core'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import {
  PORTAL_TASKS_LIST,
  PORTAL_TASKS_KANBAN,
  PORTAL_TASKS_DOCUMENTS,
} from '@/client-portal/router/types'
import { useRouter } from '@/v2/lib/composition/useRouter'
import ProjectTaskEditor from '@/components/ProjectTaskEditor.vue'

const ROUTE_NAMES = {
  list: PORTAL_TASKS_LIST,
  board: PORTAL_TASKS_KANBAN,
  documents: PORTAL_TASKS_DOCUMENTS,
}

const DISABLED_FIELDS = {
  documents: ['public'],
}

const { useState } = createNamespacedHelpers('globalFlags')

export default defineComponent({
  name: 'ViewClientPortalTask',
  components: {
    ProjectTaskEditor,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    sectionId: {
      type: String,
      default: null,
    },
    documentId: {
      type: String,
      default: null,
    },
    taskId: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
      validator: val => ['list', 'board', 'documents'].includes(val),
    },
  },
  setup(props) {
    const editor = ref(null)
    const { routerPush } = useRouter()

    const disabledFields = computed(() => DISABLED_FIELDS[props.mode] ?? [])

    const { taskEditorNoClose } = useState(['taskEditorNoClose'])

    const close = () => {
      if (!taskEditorNoClose.value) {
        routerPush({
          name: ROUTE_NAMES[props.mode],
        })
      }
    }

    onClickOutside(editor, close)

    return {
      close,
      editor,
      disabledFields,
    }
  },
})
</script>
