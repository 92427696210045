
<TabsPageHeader
  :tabs="tabs"
  :search="!$isMobile"
  :search-keyword.sync="searchKeyword"
  :search-placeholder="$t('tasks.searchPlaceholder')"
>
  <template #actions>
    <ProjectTasksViewOptions
      v-if="!$isMobile"
      class="mr-8"
      :value="viewContext"
      :hidden="['publicTasks', 'privateTasks']"
      @input="val => $emit('update:view-context', val)"
    />
  </template>
</TabsPageHeader>
