
<LayoutClientPortalGuest :organization="organization" :domain="{}">
  <h1 class="project-page-subtitle mb-32">
    {{ $t('login.password-set-required') }}
  </h1>
  <UserSettingsPassword
    label-password="Password"
    label-confirm-password="Confirm Password"
    @success="onSuccess"
  >
    <template #buttons>
      <Button
        class="mt-40 portal-button"
        size="md"
        type="submit"
        variant="primary"
      >
        {{ $t('login.password-save') }}
      </Button>
    </template>
  </UserSettingsPassword>
</LayoutClientPortalGuest>
