
<DocumentGet
  :id="documentId ?? folderId"
  v-slot="{ document, isGetPending: isPending }"
>
  <template v-if="!isPending">
    <DocumentViewContext v-if="document" v-slot="{ viewContext, updateViewContext }">
      <ProjectTheme
        :project="project"
        :class-name="[
          'h-full',
          project?.portalTheme === 'dark' ? 'bg-darkGray-1000':'bg-page print:bg-white'
        ].join(' ')"
        wrapper-class="flex-1 h-full"
        no-dark-mode
      >
        <Provide
          v-if="!isPending && document"
          :provide="{ organization, project, document, scrollTo }"
        >
          <router-view
            :organization="organization"
            :project="project"
            :document="document"
            :view-context="viewContext"
            @update-view-context="updateViewContext"
          />
        </Provide>
      </ProjectTheme>
    </DocumentViewContext>
    <div v-else>Document no longer available.</div>
  </template>
</DocumentGet>
