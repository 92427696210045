<template>
  <b-form-select :value="$root.$i18n.locale" @change="changeLang">
    <b-form-select-option
      v-for="(lang, i) in locales"
      :key="`lang-${i}`"
      :value="lang"
    >
      {{ localesNames[lang] }}
    </b-form-select-option>
  </b-form-select>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import useConfig from '@/v2/lib/composition/useConfig'
import { useUser } from '@/v2/services/users/usersCompositions'
import useLocalization from '@/v2/lib/composition/useLocalization'

export default defineComponent({
  name: 'SelectLanguage',
  setup() {
    const { user } = useUser()
    const { locales, localesNames } = useConfig(['locales', 'localesNames'])
    const { setLocale } = useLocalization()

    const changeLang = async lang => {
      await setLocale(lang)
      await user.value.patch({
        data: {
          preferences: {
            locale: lang,
          },
        },
      })
    }

    return {
      locales,
      changeLang,
      localesNames,
      user,
    }
  },
})
</script>
