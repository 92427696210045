
<DocumentGet
  :id="documentId"
  v-slot="{ document, isGetPending: isPending }"
>
  <Provide v-if="!isPending" :provide="{ document }">
    <ClientPortalLibraryDocument
      :organization="organization"
      :project="project"
      :document="document"
    />
  </Provide>
</DocumentGet>
