import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '@/v2/services/feathersClientService'

import documentEditor from '@/v2/store/documentEditor'
import auth from '@/v2/services/auth/authStore'
import billables from '@/v2/services/billables/billablesStore'
import configurations from '@/v2/services/configurations/configurationsStore'
import contentBlocks from '@/v2/services/contentBlocks/contentBlocksStore'
import documents from '@/v2/services/documents/documentsStore'
import documentNodes from '@/v2/services/documentNodes/documentNodesStore'
import documentStructures from '@/v2/services/documentStructures/documentStructuresStore'
import documentEvents from '@/v2/services/documentEvents/documentEventsStore'
import members from '@/v2/services/members/membersStore'
import myMembers from '@/v2/services/myMembers/myMembersStore'
import organizations from '@/v2/services/organizations/organizationsStore'
import domains from '@/v2/services/domains/domainsStore'
import profiles from '@/v2/services/profiles/profilesStore'
import projects from '@/v2/services/projects/projectsStore'
import projectEvents from '@/v2/services/projectEvents/projectEventsStore'
import users from '@/v2/services/users/usersStore'
import comments from '@/v2/services/comments/commentsStore'
import actionItems from '@/v2/services/actionItems/actionItemsStore'
import clients from '@/v2/services/clients/clientsStore'
import notifications from '@/v2/services/notifications/notificationsStore'
import downloads from '@/v2/services/downloads/downloadsStore'
import taskSections from '@/v2/services/taskSections/taskSectionsStore'
import globalFlags from '@/v2/store/globalFlags'

Vue.use(Vuex)
Vue.use(FeathersVuex)

export default new Vuex.Store({
  plugins: [
    auth,
    users,
    profiles,
    organizations,
    domains,
    members,
    myMembers,
    projects,
    projectEvents,
    documents,
    documentStructures,
    documentNodes,
    documentEvents,
    contentBlocks,
    billables,
    comments,
    actionItems,
    clients,
    configurations, // Required by DocumentContentBlockCostBreakdown.vue
    notifications,
    taskSections,
    downloads,
  ],
  modules: {
    documentEditor, // Needed for `commentsSidebarStatus` & other similar
    globalFlags,
  },
})
