<template>
  <div class="flex items-center">
    <b-dropdown
      v-if="user"
      ref="menu"
      dropup
      right
      offset="0"
      variant="link"
      class="dropdown-admin flex flex-1 w-full"
      menu-class="overflow-y-auto max-h-[90vh] text-13 shadow-2xl dropdown-user"
      toggle-class="
        p-0 flex justify-start w-full flex-1 group
        focus:outline-none focus:shadow-none h-32
      "
      no-caret
    >
      <template #button-content>
        <Avatar
          v-if="profile"
          :size="32"
          :name="profile.fullName"
          :src="profile.profilePhotoUrl"
          v-bind="avatarProps"
        />
        <div
          v-if="!$isMobile"
          class="
            break-all flex-1 text-left ml-8
            font-normal text-gray-700
            group-hover:text-gray-1000 whitespace-normal
            word-wrap
            dark:text-darkGray-400 dark:group-hover:text-white
          "
        >
          {{ profile.firstName }}
        </div>
        <!-- <span class="h-20 text-gray-400 icon-arrow-up text-20 dropdown-caret" /> -->
      </template>

      <b-dropdown-text
        class="pointer-events-none"
        text-class="
            px-8 font-semibold
            text-gray-700 dark:text-darkGray-300 text-14 pb-0
          "
      >
        {{ profile.firstName }}
      </b-dropdown-text>
      <b-dropdown-text
        class="pointer-events-none w-208"
        text-class="
            px-8 font-normal text-gray-500 dark:text-darkGray-400
            text-12 text-ellipsis overflow-hidden
          "
      >
        {{ user.email }}
      </b-dropdown-text>
      <!-- Change Languafge  -->
      <DropdownItem
        icon="icon_v2-so_globe"
        :label="$t('common.change-language')"
        :to="{ name: PORTAL_USER_SETTINGS_LANGUAGE } "
      />

      <!-- Account  -->
      <DropdownItem
        icon="icon_v2-so_menu-user"
        :label="$t('settings.settings')"
        :to="{
          name: PORTAL_USER_SETTINGS,
        }"
      />

      <!-- Log Out -->
      <b-dropdown-divider />

      <DropdownItem
        icon="icon_v2-so_logout"
        :label="$t('common.logout')"
        @dropdown-item-select="logout"
      />
    </b-dropdown>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import useNotification from '@/v2/lib/composition/useNotification'
import { useCurrentProfile } from '@/v2/services/profiles/profilesCompositions'
import { useUser } from '@/v2/services/users/usersCompositions'
import {
  PORTAL_USER_SETTINGS,
  PORTAL_USER_SETTINGS_LANGUAGE,
} from '@/client-portal/router/types'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'
import DropdownItem from '@/components/DropdownItem.vue'

export default defineComponent({
  name: 'DropdownUserPortal',
  components: {
    Avatar,
    DropdownItem,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    avatarOptions: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const notification = useNotification()
    const { profile } = useCurrentProfile()

    const { user, logout } = useUser()

    const avatarProps = computed(() => ({
      size: 24,
      fontSize: '11',
      ...props.avatarOptions,
    }));

    return {
      profile,
      notification,
      user,
      logout,
      avatarProps,
      PORTAL_USER_SETTINGS,
      PORTAL_USER_SETTINGS_LANGUAGE,
    }
  },
})
</script>
<style lang="postcss">
.dropdown-user {
  margin-top: 5px !important;
}
</style>
