<template>
  <LayoutPortalGuest v-if="error">
    <div class="flex flex-col items-center justify-center">
      <div class="max-w-max flex flex-col items-center justify-center">
        <Error
          variant="danger"
        >
          <p class="max-w-[24ch]">The login link you used is invalid or has expired.</p>
        </Error>
        <router-link
          v-slot="{ navigate }"
          :to="{ name: PORTAL_LOGIN }"
          custom
        >
          <Button
            class="mt-16"
            type="button"
            size="lg"
            variant="neutral"
            @click="navigate"
          >
            Request login link
            <span class="icon-arrow-right-util ml-8"></span>
          </Button>
        </router-link>
      </div>
    </div>
  </LayoutPortalGuest>

  <SpinnerOverlay v-else :label="spinnerLabel" />
</template>
<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import { useUser } from '@/v2/services/users/usersCompositions'
import { useRouteParams } from '@/v2/lib/composition/useRouter'
import { PORTAL_LOGIN } from '@/client-portal/router/types'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import Error from '@/components/Error.vue'
import Button from '@/components/Button.vue'
import LayoutPortalGuest from '@/views/LayoutPortalGuest.vue'

export default defineComponent({
  name: 'ViewClientPortalAuth',
  components: {
    SpinnerOverlay,
    Error,
    Button,
    LayoutPortalGuest,
  },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { authenticateToken } = useUser()
    const { token } = useRouteParams(['token'])

    const loggedIn = ref(false)
    const error = ref(null)

    const spinnerLabel = computed(
      () => (loggedIn.value ? 'Redirecting...' : 'Logging in...')
    )

    const login = async () => {
      loggedIn.value = false
      try {
        await authenticateToken(token.value)
        loggedIn.value = true
      } catch (err) {
        console.error(err)
        error.value = err.message
      }
    }

    login()

    return {
      error,
      spinnerLabel,
      PORTAL_LOGIN,
    }
  },
})
</script>
