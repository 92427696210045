<template>
  <div class="-mx-16 -mt-16">
    <ProjectDashboard
      :organization="organization"
      :project="project"
      :routes="routes"
      :action-items="actionItemsSorted"
      :is-portal-preview="preview"
    />
  </div>
</template>

<script>
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { rejectNil } from 'ramda-extension'
import { isNil, pluck } from 'ramda'
import {
  defineComponent,
  computed,
  getCurrentInstance,
  inject,
} from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import {
  PORTAL_FS_FOLDER,
  PORTAL_LINKED_APP,
  PORTAL_PACKAGED_SERVICES,
  PORTAL_TASKS,
  PORTAL_TASKS_LIST_TASK,
} from '@/client-portal/router/types'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import ProjectDashboard from '@/components/ProjectDashboard.vue'

export default defineComponent({
  name: 'ViewClientPortalDashboard',
  components: {
    ProjectDashboard,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      default: null,
    },
    project: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    useBrowserTitle(
      computed(() => [props.project.name, props.organization.name].join(' - '))
    )

    const isClientPortal = inject('isClientPortal', false)
    const vm = getCurrentInstance().proxy
    const { Document, ActionItem } = vm.$FeathersVuex.api

    const routes = computed(() => ({
      docs: {
        name: PORTAL_FS_FOLDER,
        params: {
          folderId: props.project?.publicFolder,
        },
      },
      linkedApp: { name: PORTAL_LINKED_APP },
      packagedServices: { name: PORTAL_PACKAGED_SERVICES },
      tasks: { name: PORTAL_TASKS },
    }))

    const projectsFindParams = computed(() => (props.client
      ? {
        query: {
          organization: props.organization._id,
          client: props.client._id,
        },
      }
      : {
        query: {
          _id: props.project._id,
        },
      }))

    const docParams = computed(() => ({
      query: rejectNil({
        project: props.project._id,
        category: {
          $nin: [
            DOCUMENT_CATEGORY.ProjectRootFolder,
            DOCUMENT_CATEGORY.ProjectPublicFolder,
          ],
        },
        sharingClientEnabled: isClientPortal ? true : null,
        $sort: { updatedAt: -1 },
        $limit: 6,
      }),
    }))

    const { items: documents } = useFind({
      model: Document,
      params: docParams,
    })

    const sharedDocsIds = computed(() => (isClientPortal ? pluck('_id', documents.value) : null))

    const actionItemsParams = computed(() => ({
      query: rejectNil({
        project: props.project._id,
        isResolved: false,
        public: true,
        $sort: {
          dueDate: 1,
        },
        $limit: 9,
      }),
    }))
    const { items: actionItems } = useFind({
      model: ActionItem,
      params: actionItemsParams,
    })

    // const knowledgeDocs = computed(() => documents.value
    //   .filter(doc => doc.category === DOCUMENT_CATEGORY.DocumentKnowledgeBase))

    // const knowledgeDocsToDisplayInCard = computed(() => knowledgeDocs.value.slice(0, 4))

    // Filter public action items to decide if they actually visible to the client
    //
    // Example: document is not shared, but action item is public
    //          in this case the action item is NOT visibile in the client portal
    const actionItemsShared = computed(() => {
      // the ones from projects
      const itemsFromProject = actionItems.value
        .filter(item => isNil(item.document))

      // the ones from shared documents
      const itemsFromSharedDocs = actionItems.value
        .filter(item => sharedDocsIds.value.includes(item.document))

      return [...itemsFromProject, ...itemsFromSharedDocs]
    })
    const actionItemsSorted = computed(() => {
      const itemsWithDate = actionItemsShared.value.filter(item => isNotNilOrEmpty(item.dueDate))
      const itemsWithoutDate = actionItemsShared.value.filter(item => isNil(item.dueDate))

      return [...itemsWithDate, ...itemsWithoutDate]
    })

    return {
      routes,
      projectsFindParams,
      documents,
      actionItems,
      actionItemsSorted,
    }
  },
})
</script>
