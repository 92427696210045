<template>
  <ProjectViewContext v-slot="{ viewContext, updateViewContext }">
    <div>
      <PageHeader title="Projects">
        <template #actions>
          <ProjectViewOptions
            :value="viewContext"
            no-group-by
            @input="updateViewContext"
          />
        </template>
      </PageHeader>
      <section>
        <ProjectList
          :menu-items="null"
          :organization-id="organization._id"
          class="mb-32"
          :view-context="viewContext"
          :query="{ archived: { $ne: true } }"
          hide-amounts
          @open-project="open"
        />
      </section>
    </div>
  </ProjectViewContext>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { PORTAL_PROJECT } from '@/client-portal/router/types'
import { useRouter } from '@/v2/lib/composition/useRouter'
import ProjectList from '@/components/ProjectList.vue'
import Button from '@/components/Button.vue'
import ProjectViewContext from '@/components/ProjectViewContext.vue'
import ProjectViewOptions from '@/components/ProjectViewOptions.vue'
import PageHeader from '@/components/PageHeader.vue'

export default defineComponent({
  name: 'ViewClientPortalProjects',
  components: {
    ProjectList,
    Button,
    ProjectViewContext,
    ProjectViewOptions,
    PageHeader,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { routerPush } = useRouter()

    const open = projectId => {
      routerPush({
        name: PORTAL_PROJECT,
        params: { projectId },
      })
    }

    return {
      open,
    }
  },
})
</script>
