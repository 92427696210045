<template>
  <ProjectTheme
    :project="project"
    :wrapper-class="
      project?.portalTheme === 'dark'
        ? 'bg-darkGray-1000'
        : 'bg-page'
    "
  >
    <ProjectPermissions
      v-slot="{ permissions }"
      :project="project"
    >
      <DocRenderer :document="document">
        <template #breadcrumbs>
          <DocBreadcrumbs :document="document" :project="project" />
        </template>
        <template #actions>
          <DocFolderViewOptions
            :value="viewContext"
            @input="value => $emit('update-view-context', value)"
          />

          <DocCreateButton v-if="permissions.create" />
        </template>
        <template #header>
          <DocTitleReadonly :project="project" />
        </template>

        <template #content>
          <div v-if="document" v-fragment>
            <FolderContentList
              :document="document"
              :view-context="viewContext"
              :permissions="permissions"
            />
            <RecentDocuments
              v-if="recentlyUpdatedModule.enabled"
              :project="project"
              hide-private
            />
          </div>
        </template>
      </DocRenderer>
    </ProjectPermissions>
  </ProjectTheme>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useProjectModule } from '@/v2/services/projects/compositions'
import ProjectTheme from '@/components/ProjectTheme.vue'
import FolderContentList from '@/components/FolderContentList.vue'
import RecentDocuments from '@/components/RecentDocuments.vue'
import DocBreadcrumbs from '@/components/Doc/DocBreadcrumbs.vue'
import DocCreateButton from '@/components/Doc/DocCreateButton.vue'
import DocFolderViewOptions from '@/components/Doc/DocFolderViewOptions.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitleReadonly from '@/components/Doc/DocTitleReadonly.vue'
import ProjectPermissions from '@/components/ProjectPermissions.vue'

export default defineComponent({
  name: 'ClientPortalFSFolder',
  components: {
    FolderContentList,
    RecentDocuments,
    ProjectTheme,
    DocBreadcrumbs,
    DocCreateButton,
    DocFolderViewOptions,
    DocRenderer,
    DocTitleReadonly,
    ProjectPermissions,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const recentlyUpdatedModule = useProjectModule(computed(() => props.project), 'recentlyUpdated')

    return {
      recentlyUpdatedModule,
    }
  },
})
</script>
