
<LayoutPortalGuest v-if="error">
  <div class="flex flex-col items-center justify-center">
    <div class="max-w-max flex flex-col items-center justify-center">
      <Error
        variant="danger"
      >
        <p class="max-w-[24ch]">The login link you used is invalid or has expired.</p>
      </Error>
      <router-link
        v-slot="{ navigate }"
        :to="{ name: PORTAL_LOGIN }"
        custom
      >
        <Button
          class="mt-16"
          type="button"
          size="lg"
          variant="neutral"
          @click="navigate"
        >
          Request login link
          <span class="icon-arrow-right-util ml-8"></span>
        </Button>
      </router-link>
    </div>
  </div>
</LayoutPortalGuest>

<SpinnerOverlay v-else :label="spinnerLabel" />
