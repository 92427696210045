<template>
  <DocumentGet
    :id="documentId ?? folderId"
    v-slot="{ document, isGetPending: isPending }"
  >
    <template v-if="!isPending">
      <DocumentViewContext v-if="document" v-slot="{ viewContext, updateViewContext }">
        <ProjectTheme
          :project="project"
          :class-name="[
            'h-full',
            project?.portalTheme === 'dark' ? 'bg-darkGray-1000':'bg-page print:bg-white'
          ].join(' ')"
          wrapper-class="flex-1 h-full"
          no-dark-mode
        >
          <Provide
            v-if="!isPending && document"
            :provide="{ organization, project, document, scrollTo }"
          >
            <router-view
              :organization="organization"
              :project="project"
              :document="document"
              :view-context="viewContext"
              @update-view-context="updateViewContext"
            />
          </Provide>
        </ProjectTheme>
      </DocumentViewContext>
      <div v-else>Document no longer available.</div>
    </template>
  </DocumentGet>
</template>
<script>
import { noop } from 'ramda-extension'
import { defineComponent, inject, toRef, provide } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useRouteParams } from '@/v2/lib/composition/useRouter'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import DocumentGet from '@/components/DocumentGet.vue'
import DocumentViewContext from '@/components/DocumentViewContext.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import Provide from '@/components/Provide.vue'

const { useState: useStateDocumentEditor } =
  createNamespacedHelpers('documentEditor')

const UIConfig = {
  [DOCUMENT_CATEGORY.ProjectPublicFolder]: {
    hideSidebar: true,
  },
}

export default defineComponent({
  name: 'ViewClientPortalFS',
  components: {
    DocumentViewContext,
    ProjectTheme,
    DocumentGet,
    Provide,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    folderId: {
      type: String,
      required: true,
    },
    isPinnedFolder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    provide('isPinnedFolder', toRef(props, 'isPinnedFolder'))

    const { documentId } = useRouteParams(['documentId'])
    const { documentSidebarStatus } = useStateDocumentEditor([
      'documentSidebarStatus',
    ])

    return {
      UIConfig,
      documentId,
      documentSidebarStatus,
      scrollTo: noop,
    }
  },
})
</script>
