
<div>
  <PageHeader :title="$t('settings.notifications')" />
  <div class="max-w-form">
    <p class="mb-12">{{ $t('settings.notifications-email-description') }}</p>
    <UserSettingsNotificationsForm
      :organization="organization"
      :user="user"
      :model="model"
    >
      <template #button="{ submit, isBusy, hasChanges }">
        <PageFooter>
          <Button
            :is-loading="isBusy"
            size="lg"
            variant="primary"
            type="submit"
            @click="submit"
          >
            {{ $t('common.save-changes') }}
          </Button>
          <template v-if="hasChanges" #right>
            <PageFooterMessage />
          </template>
        </PageFooter>
      </template>
    </UserSettingsNotificationsForm>
  </div>
</div>
