
<ProjectViewContext v-slot="{ viewContext, updateViewContext }">
  <div>
    <PageHeader title="Projects">
      <template #actions>
        <ProjectViewOptions
          :value="viewContext"
          no-group-by
          @input="updateViewContext"
        />
      </template>
    </PageHeader>
    <section>
      <ProjectList
        :menu-items="null"
        :organization-id="organization._id"
        class="mb-32"
        :view-context="viewContext"
        :query="{ archived: { $ne: true } }"
        hide-amounts
        @open-project="open"
      />
    </section>
  </div>
</ProjectViewContext>
