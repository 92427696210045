
<div class="portal-paragraph-font">
  <RecoverPasswordFormReset
    v-show="state === STATE.TOKEN_VALID"
    :token="token"
    button-class="portal-button"
    @state-change="onStateChange"
  />
  <div
    v-if="state === STATE.TOKEN_ERROR || state === STATE.PASSWORD_ERROR"
    class="flex flex-col items-center justify-center text-center"
  >
    <span class="w-48 my-32 text-red-600 icon-alert text-48"></span>
    <h2 class="text-center title-h2">{{ $t('login.whoops') }}</h2>
    <p v-if="state === STATE.TOKEN_ERROR" class="text-gray-700">
      {{ $t('login.password-reset-invalid-token') }}<br />{{ $t('login.try-again') }}
    </p>
    <p v-else-if="state === STATE.PASSWORD_ERROR" class="text-gray-700">
      {{ $t('login.password-reset-error') }}<br />{{ $t('login.contact-us') }}
    </p>
  </div>
  <div
    v-else-if="state === STATE.PASSWORD_CHANGED"
    class="flex flex-col items-center justify-center text-center"
  >
    <span class="w-48 text-green-600 icon_v2-so_tick text-48"></span>
    <h2 class="title-h2">{{ $t('login.great') }}</h2>
    <p class="text-gray-700 mb-32">
      {{ $t('login.password-changed') }}
    </p>
    <b-link
      :to="{ name: PORTAL_LOGIN }"
      class="btn btn-default btn-md portal-button"
    >
      {{ $t('login.login') }}
    </b-link>
  </div>
</div>
