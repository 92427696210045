// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register';

import * as R from 'ramda'
import 'scroll-behavior-polyfill'
import './assets/scss/bootstrap.scss'
import './assets/css/tailwind.css'
import './assets/css/font-icons.css'
import './assets/css/font-icons-v2.css'
import './assets/css/font-logos.css'
import './assets/css/font-brands.css'
import './assets/css/font-standard.css'
import './assets/css/font-material.css'

// //3rd party components
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import retina from 'retinajs'
import VueRetina from 'vue-retina'
import VueMeta from 'vue-meta'
import BootstrapVue from 'bootstrap-vue'
import PortalVue from 'portal-vue'
import VueStaticData from 'vue-static-data'
import ComponentID from 'vue-component-id'
import VueScrollTo from 'vue-scrollto'
import VueFragment from 'vue-frag'
import VueMq from 'vue-mq'
import VueMasonry from 'vue-masonry-css'
import VueObserveVisibility from 'vue-observe-visibility'

import i18n from './i18n'

import './directives'
import './filters'
import './svg-icons'

import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import store from '@/client-portal/store/store'
import routerFactory from './client-portal/router/router'
import ClientPortalApp from './ClientPortalApp.vue'

import TrackingPlugin from '@/v2/services/tracking/plugin'
import InjectPlugin from './lib/plugins/inject'

import config from './config'
import container from './container'

useBrowserTitle.debug = import.meta.env.DEV

Object.defineProperty(window, 'APP_VERSION', {
  enumerable: false,
  configurable: false,
  writable: false,
  value: import.meta.env.VITE_APP_VERSION ?? null,
});

container.set('config', config)

const router = routerFactory(container, {
  scrollTop: true,
});

Vue.config.productionTip = false
Vue.use(VueCompositionAPI)
Vue.use(VueRetina, { retina })
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.use(VueMasonry)
Vue.use(PortalVue)
Vue.use(VueStaticData)
Vue.use(ComponentID)
Vue.use(VueScrollTo)
Vue.use(VueObserveVisibility)

Vue.use(TrackingPlugin, {
  router,
  store,
  config: {
    enable: false,
    debug: false,
  },
})

Vue.directive('fragment', VueFragment);


Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: Infinity,
  },
  defaultBreakpoint: 'sm', // customize this for SSR
})

Object.defineProperty(Vue.prototype, '$isMobile', {
  get() {
    return ['sm', 'md'].includes(this.$mq)
  },
});

const eventBus = new Vue()

Vue.use(InjectPlugin(), { name: '$container', dependency: container })
Vue.use(InjectPlugin(), { name: '$eventBus', dependency: eventBus })

Vue.directive('fragment', VueFragment);

const makeToasterOptions = R.pipe(
  R.mergeDeepRight({
    solid: true,
    toaster: 'b-toaster-top-right',
    autoHideDelay: 3000,
  })
)

new Vue({
  router,
  store,
  created() {
    this.$root.$on(
      'notification',
      ({ title, message, variant = 'success' }) => {
        this.toast(message, {
          title,
          variant,
        })
      }
    )
  },
  methods: {
    toast(message, options = {}) {
      this.$bvToast.toast(message, makeToasterOptions(options))
    },
  },
  render: h => h(ClientPortalApp),
  i18n: i18n({
    locales: config.locales,
  }),
}).$mount('#app')
