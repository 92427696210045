
<b-popover
  :target="target"
  triggers="manual blur"
  placement="rightbottom"
  :custom-class="[
    'w-320 shadow-2xl rounded-lg projects-popover',
    darkTheme && 'dark'
  ].filter(Boolean).join(' ')"
  :delay="0"
  :offset="0"
  :show="show"
  v-on="$listeners"
>
  <template #title>
    <div class="flex justify-between">
      <Button
        variant="neutral"
        size="sm"
        @click="goToAllProjects"
      >
        <span class="mr-4 -ml-4 icon_v2-so_list-view text-blue-600"></span>
        <span>{{ $t('common.projects') }}</span>
      </Button>
      <CheckMemberRole v-slot="{ hasRole }" :roles="['admin', 'projectManager']">
        <Button
          v-if="hasRole"
          variant="neutral"
          size="sm"
          @click="newProject"
        >
          <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600"></span>
          <span>New Project</span>
        </Button>
      </CheckMemberRole>
    </div>
  </template>
  <ul class="max-h-490 overflow-y-auto">
    <li
      v-for="project in projects"
      :key="project._id"
      class="
        hover:bg-gray-100 dark:text-white
        dark:hover:bg-gray-300 dark:hover:text-gray-700 p-4
        rounded-md px-8 mb-4 flex items-center justify-between
      "
      :class="{
        'bg-gray-100 dark:bg-gray-300 relative cursor-default pr-28 dark:text-darkGray-700':
          project._id === currentProject?._id,
        'cursor-pointer': project._id !== currentProject?._id,
      }"
      @click="select(project)"
    >
      <ProjectSwitcherItem
        :project="project"
        :selected="project._id === currentProject?._id"
        :show-client="showClient"
        :dark-theme="darkTheme"
      />
      <span
        v-if="project._id === currentProject?._id"
        class="
          icon_v2-so_tick absolute right-4
          top-6 text-gray-400 pointer-events-none
        "
      />
    </li>
  </ul>
</b-popover>
