<template>
  <div>
    <InfoBox
      :title="$t('tasks.fromDocsAndFilesTitle')"
      icon="icon_v2-so_docs-files"
    >
      {{ $t('tasks.fromDocsAndFilesInfo') }}
    </InfoBox>
    <FeatureLimit
      v-slot="{ checkFeatureLimit: checkActionItemsLimit }"
      flag="limitActionItems"
      label="action items"
      @allow="createTask"
    >
      <TaskBoard
        :selected-task-id="$route.params.taskId"
        :sections="sections"
        readonly-sections
        lock-tasks-to-section
        no-section-pin-to-dashboard
        no-task-reordering
        no-section-menu
        @section-menu-action="handleSectionMenuAction"
        @create-task="checkActionItemsLimit"
        @update-task="updateTask"
        @remove-task="removeTask"
        @move-task="moveTask"
      >
        <template #section-title="{ section }">
          <ProjectTasksDocumentTitle :document="section.document" />
        </template>
        <template #task="{ section, task }">
          <router-link
            v-slot="{ navigate }"
            :to="{
              name: PORTAL_TASKS_DOCUMENTS_TASK,
              params: {
                documentId: section.document._id,
                taskId: task._id
              }
            }"
            custom
          >
            <ProjectTaskListItem
              :task="task"
              hide-drag-handle
              hide-visibility-badge
              :hide-delete-button="readonlyTask(task)"
              @update="updateTask"
              @remove="removeTask(section, task)"
              @click="navigate"
            />
          </router-link>
        </template>
      </TaskBoard>
    </FeatureLimit>
    <router-view
      :project="project"
      @remove="task => removeTask(null, task)"
      @created="goToTasks"
      @updated="goToTasks"
    />
  </div>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useDocumentsTasks } from '@/v2/services/projects/compositions'
import { PORTAL_TASKS_DOCUMENTS, PORTAL_TASKS_DOCUMENTS_TASK } from '@/client-portal/router/types'
import { useUser } from '@/v2/services/users/usersCompositions'
import TaskBoard from '@/components/Tasks/TaskBoard.vue'
import ProjectTaskListItem from '@/components/ProjectTaskListItem.vue'
import InfoBox from '@/components/InfoBox.vue'
import ProjectTasksDocumentTitle from '@/components/ProjectTasksDocumentTitle.vue'
import FeatureLimit from '@/components/FeatureLimit.vue'

export default defineComponent({
  name: 'ViewClientPortalTasksDocuments',
  components: {
    TaskBoard,
    ProjectTaskListItem,
    InfoBox,
    ProjectTasksDocumentTitle,
    FeatureLimit,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { routerPush } = useRouter()
    const project = computed(() => props.project)

    const options = computed(() => ({
      viewContext: props.viewContext,
      keyword: props.keyword,
      documentsQuery: {
        tasks: { $ne: [] },
      },
    }))

    const {
      sections,
      handleSectionMenuAction,

      // tasks
      removeTask,
      updateTask,
      moveTask,
    } = useDocumentsTasks(project, options)

    const createTask = async section => {
      routerPush({
        name: PORTAL_TASKS_DOCUMENTS_TASK,
        params: {
          documentId: section.document._id,
          taskId: 'new',
        },
      })
    }

    const goToTasks = () => routerPush({ name: PORTAL_TASKS_DOCUMENTS })

    const { user } = useUser()
    const readonlyTask = task => task.createdBy !== user.value._id

    return {
      sections,
      handleSectionMenuAction,

      // tasks
      createTask,
      updateTask,
      removeTask,
      moveTask,
      readonlyTask,

      goToTasks,

      PORTAL_TASKS_DOCUMENTS_TASK,
    }
  },
})
</script>
