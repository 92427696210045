
<ClientPortalPasswordCreateGuard :organization="organization">
  <LayoutApp
    :dark-theme="project?.isDarkTheme"
    mobile-sticky-footer
  >
    <template #header>
      <MainHeader
        :organization="organization"
        :client="client"
        :dark-theme="project?.isDarkTheme"
        no-switch
      >
        <template v-if="!$isMobile" #nav>
          <PageHeaderSearch
            v-if="project"
            v-model="keyword"
            :placeholder="$t('project-search.placeholder-header')"
            :show-result="showSearchResult"
            @close="closeSearchResult"
          >
            <ProjectDashboardPageHeaderSearch :keyword="keyword" />
          </PageHeaderSearch>
        </template>
        <template #user>
          <DropdownUserPortal :organization="organization" />
        </template>
      </MainHeader>
    </template>
    <template #main>
      <router-view
        :organization="organization"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
  </LayoutApp>
</ClientPortalPasswordCreateGuard>
