<script>
import { defineComponent } from '@vue/composition-api'
import useDomain from '@/v2/services/domains/useDomain'

export default defineComponent({
  name: 'Domain',
  setup(props, context) {
    const { domain, isPending, error } = useDomain()

    return () => {
      if (isPending.value) {
        return null;
      }

      return context.slots.default({
        domain: domain.value,
        error: error.value,
      });
    }
  },
})
</script>
