<script>
import { computed, defineComponent, watch } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useUser } from '@/v2/services/users/usersCompositions'
import { PORTAL_PASSWORD_SET } from '@/client-portal/router/types'

export default defineComponent({
  name: 'ClientPortalPasswordCreateGuard',
  components: {

  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { routerReplace } = useRouter()
    const { user } = useUser()

    const isPasswordSetRequired = computed(
      () => props.organization.portalLoginPasswordEnabled && !user.value.hasPassword
    )

    const redirect = () => {
      routerReplace({ name: PORTAL_PASSWORD_SET })
    }

    watch(isPasswordSetRequired, val => {
      if (val) {
        redirect()
      }
    }, { immediate: true })

    return () => (isPasswordSetRequired.value ? null : context.slots.default({}))
  },
})
</script>
