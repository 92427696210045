<template>
  <DocumentGet
    :id="documentId"
    v-slot="{ document, isGetPending: isPending }"
  >
    <Provide v-if="!isPending" :provide="{ document }">
      <ClientPortalLibraryDocument
        :organization="organization"
        :project="project"
        :document="document"
      />
    </Provide>
  </DocumentGet>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import DocumentGet from '@/components/DocumentGet.vue'
import Provide from '@/components/Provide.vue'
import ClientPortalLibraryDocument from '@/components/ClientPortalLibraryDocument.vue'

export default defineComponent({
  name: 'ViewClientPortalDocument',
  components: {
    DocumentGet,
    ClientPortalLibraryDocument,
    Provide,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
  },
})
</script>
