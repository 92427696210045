<template>
  <component
    :is="component"
    v-bind="$props"
    v-on="$listeners"
  />
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import ClientPortalFSDocument from '@/components/ClientPortalFSDocument.vue'
import ClientPortalFSFile from '@/components/ClientPortalFSFile.vue'
import ClientPortalLibraryDocument from '@/components/ClientPortalLibraryDocument.vue'

const componentMap = {
  [DOCUMENT_CATEGORY.Document]: ClientPortalFSDocument,
  [DOCUMENT_CATEGORY.File]: ClientPortalFSFile,
  [DOCUMENT_CATEGORY.FileLink]: ClientPortalFSFile,
  [DOCUMENT_CATEGORY.DocumentCaseStudy]: ClientPortalLibraryDocument,
  [DOCUMENT_CATEGORY.DocumentTeamBio]: ClientPortalLibraryDocument,
  [DOCUMENT_CATEGORY.DocumentPackagedService]: ClientPortalLibraryDocument,
};

export default defineComponent({
  name: 'ViewClientPortalFSDocument',
  components: {
    ClientPortalFSDocument,
    ClientPortalFSFile,
    ClientPortalLibraryDocument,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const component = computed(() => componentMap[props.document.category]);

    return {
      component,
    }
  },
})
</script>
