import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes'

Vue.use(Router)

export default function routerFactory(container, options = {}) {
  const { scrollTop = false, scrollElementSelector = null } = options;

  const router = new Router({
    mode: import.meta.env.VITE_CLIENT_PORTAL_ROUTER_MODE ?? 'history',
    base: import.meta.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      return scrollTop ? { y: 0 } : savedPosition
    }
  })

  // if (scrollTop) {
  //   router.afterEach(() => {
  //     const el = document.querySelector(scrollElementSelector)
  //     if (el) {
  //       el.scrollTop = 0
  //       return
  //     }

  //     window.scrollTo(0, 0)
  //   })
  // }

  container.set('router', router)
  return router
}
