<template>
  <div v-if="activeOrganization" class="client-portal">
    <SpinnerOverlay v-if="isLoading" />
    <Configuration v-else v-slot="{ isPending: isConfigurationPending }">
      <router-view
        v-if="!isConfigurationPending"
        :domain="domain"
        :user="user"
        :organization="organization"
        :client="client"
        :projects="projects"
        :preview="isPortalPreview"
      />
    </Configuration>
  </div>
  <LayoutPortalGuest v-else>
    <Error
      variant="warning"
      title="Unavailable"
    >
      <div>
        <h3>
          This portal is currently unavailable.
        </h3>
        <h4 class="text-14">Please contact {{ organization.name }} </h4>
      </div>
    </Error>
  </LayoutPortalGuest>
</template>
<script>
import {
  ref, watch, defineComponent,
  provide, computed, onMounted,
} from '@vue/composition-api'
import { useGet } from 'feathers-vuex';
import { useRouter, useRoute, useRouteQuery } from '@/v2/lib/composition/useRouter'
import { useUser } from '@/v2/services/users/usersCompositions'
import { useLocalStorageRef } from '@/v2/lib/composition/useLocalStorage'
import { useMsgBoxError } from '@/v2/lib/composition/useMsgBox'
import {
  PORTAL_PROJECTS,
  PORTAL_PROJECT,
  PORTAL_LOGIN,
} from '@/client-portal/router/types'
import config from '@/config';
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'
import { switchCond } from '@/v2/lib/helpers/fp';
import { debounceWithArgs } from '@/v2/lib/helpers/debounce'
import EventBus from '@/event-bus/event-bus'
import useNotification from '@/v2/lib/composition/useNotification'
import { useLoadMembers } from '@/v2/services/myMembers/compositions';
import useLocalization from '@/v2/lib/composition/useLocalization';
import LayoutPortalGuest from '@/views/LayoutPortalGuest.vue'
import Error from '@/components/Error.vue'
import Configuration from '@/components/Configuration.vue'
import { ROLES } from '@/v2/services/members/membersTypes';

export default defineComponent({
  name: 'ClientPortalAppController',
  components: {
    SpinnerOverlay,
    Error,
    LayoutPortalGuest,
    Configuration,
  },
  props: {
    domain: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { Client, Project } = context.root.$FeathersVuex.api
    const { user, authToken, authenticate, authenticateMember, isAuthenticated, logout } = useUser()
    const lastProjectId = useLocalStorageRef('clientPortalLastProjectId')
    const { router, routerReplace } = useRouter()
    const { routeIsRoot, routeIsChildOf, routeMetaEq, routeOwnMetaEq } = useRoute()
    const {
      project: queryProjectId,
      preview: queryPreview,
    } = useRouteQuery(['project', 'preview'])

    const loadMembers = useLoadMembers()
    const msgBoxErr = useMsgBoxError()
    const { setLocale } = useLocalization()

    const isPortalPreview = computed(() => queryPreview.value === 'true');

    const projects = ref([])
    const isLoading = ref(true)
    const clientId = ref(null)

    const organization = computed(() => props.domain.organization$);
    const activeOrganization = computed(() => organization.value.status === 'active')

    const { item: client } = useGet({ model: Client, id: clientId })


    let reloadPageOnLogout = true

    provide('isClientPortal', true)
    provide('organization', organization)

    /** Authenticates user based on `feathers-jwt` in `localStorage` */
    const loadSession = async () => {
      try {
        const { user: currentUser } = await authenticate()
        return currentUser ?? null
      } catch (error) {
        if (['NotAuthenticated'].includes(error.name)) {
          console.log(error.name)
        } else {
          console.error(error)
        }

        return null
      }
    }

    const loadProjects = async () => {
      const query = {
        organization: organization.value._id,
        client: clientId.value,
      }

      const { data } = await Project.find({ query })
      projects.value = data
    }

    /**
     * Redirects to project dashboard
     * @param {string} projectId
     */
    const redirectToProject = projectId => {
      if (!routeIsChildOf(PORTAL_PROJECT) && !routeOwnMetaEq('noRedirect', true)) {
        routerReplace({
          name: PORTAL_PROJECT,
          params: { projectId },
          query: { preview: queryPreview.value },
        })
      }
    }

    /**
     * Redirects to login page (resend magic link)
     */
    const redirectToLogin = (reloadPage = false) => {
      const navigate = reloadPage ? router.go.bind(router) : routerReplace
      return navigate({ name: PORTAL_LOGIN })
    }

    /**
     * Redirects to landing page (either projects or first / last used project)
     */
    const redirectToLanding = async () => {
      await loadProjects()

      const isValidLastProjectId = projects.value.some(
        ({ _id }) => _id === lastProjectId.value ?? null
      )

      const projectId = switchCond([
        [queryProjectId.value, queryProjectId.value],
        [isValidLastProjectId, lastProjectId.value],
        [projects.value.length === 1, projects.value?.[0]?._id],
      ])

      if (projectId) {
        redirectToProject(projectId)
        // console.warn('[!!!] [!!!] !!! !!! FIX FIX FIX FIX')
      } else {
        routerReplace({ name: PORTAL_PROJECTS })
      }
    }

    const handleNoMembers = async () => {
      msgBoxErr({
        title: 'Cannot log you in',
        message: 'Please contact us',
      })
      // Prevent page reload
      reloadPageOnLogout = false
      await logout() // Required in order to clear **user** (not member) jwt
    }

    const watchUser = () => {
      watch(user, async currentUser => {
        if (currentUser) {
          let _memberId = null
          let _clientId = null

          if (authToken.value) {
            // -- token login
            _memberId = authToken.value.member
            _clientId = authToken.value.client
          } else {
            // -- email / password login
            const { data: members } = await loadMembers({
              role: ROLES.client,
              organization: props.organization,
            })

            if (!members.length) {
              handleNoMembers()
              return
            }

            // TODO: improve this
            const member = members[0]

            _memberId = member._id
            _clientId = member.client
          }

          await authenticateMember(_memberId)
          clientId.value = _clientId

          redirectToLanding()
        } else {
          redirectToLogin(reloadPageOnLogout)
          reloadPageOnLogout = true
        }
      })
    }

    /** Implements Auth Guard. Loads projects for logged in users before accessing Project
     * Dashboard */
    const protectRoutes = () => {
      router.beforeEach((to, _from, next) => {
        if (!isAuthenticated.value && routeMetaEq('requiresAuth', true, to)) {
          return next({ name: PORTAL_LOGIN })
        }

        if (isAuthenticated.value && routeMetaEq('requiresGuest', true, to)) {
          redirectToLanding()
          return next(false)
        }

        return next()
      })
    }

    const boot = async () => {
      protectRoutes()
      watchUser()

      await setLocale(organization.value.locale)

      const requiresAuth = routeMetaEq('requiresAuth', true)
      const requiresGuest = routeMetaEq('requiresGuest', true)

      const isSessionRequired = requiresAuth || requiresGuest || routeIsRoot.value

      if (isSessionRequired) {
        const currentUser = await loadSession()
        if (!currentUser && (requiresAuth || routeIsRoot.value)) {
          redirectToLogin()
        }
      }

      isLoading.value = false
    }

    provide('organization', organization)
    provide('client', client)


    const notification = useNotification()
    EventBus.$on('messageError', debounceWithArgs(({ title, error }) => {
      const notificationData = config.isDevelopment || config.debug
        ? { title, message: error.message }
        : { title: 'Whoops!', message: 'Something went wrong. Please try again or contact support if the issue continues' }

      notification({
        ...notificationData,
        variant: 'danger',
      })
    }))

    onMounted(() => {
      boot()
    })

    return {
      organization,
      client,
      user,
      isLoading,
      projects,
      activeOrganization,
      isPortalPreview,
    }
  },
})
</script>
