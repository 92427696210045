<template>
  <b-popover
    :target="target"
    triggers="manual blur"
    placement="rightbottom"
    :custom-class="[
      'w-320 shadow-2xl rounded-lg projects-popover',
      darkTheme && 'dark'
    ].filter(Boolean).join(' ')"
    :delay="0"
    :offset="0"
    :show="show"
    v-on="$listeners"
  >
    <template #title>
      <div class="flex justify-between">
        <Button
          variant="neutral"
          size="sm"
          @click="goToAllProjects"
        >
          <span class="mr-4 -ml-4 icon_v2-so_list-view text-blue-600"></span>
          <span>{{ $t('common.projects') }}</span>
        </Button>
        <CheckMemberRole v-slot="{ hasRole }" :roles="['admin', 'projectManager']">
          <Button
            v-if="hasRole"
            variant="neutral"
            size="sm"
            @click="newProject"
          >
            <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600"></span>
            <span>New Project</span>
          </Button>
        </CheckMemberRole>
      </div>
    </template>
    <ul class="max-h-490 overflow-y-auto">
      <li
        v-for="project in projects"
        :key="project._id"
        class="
          hover:bg-gray-100 dark:text-white
          dark:hover:bg-gray-300 dark:hover:text-gray-700 p-4
          rounded-md px-8 mb-4 flex items-center justify-between
        "
        :class="{
          'bg-gray-100 dark:bg-gray-300 relative cursor-default pr-28 dark:text-darkGray-700':
            project._id === currentProject?._id,
          'cursor-pointer': project._id !== currentProject?._id,
        }"
        @click="select(project)"
      >
        <ProjectSwitcherItem
          :project="project"
          :selected="project._id === currentProject?._id"
          :show-client="showClient"
          :dark-theme="darkTheme"
        />
        <span
          v-if="project._id === currentProject?._id"
          class="
            icon_v2-so_tick absolute right-4
            top-6 text-gray-400 pointer-events-none
          "
        />
      </li>
    </ul>
  </b-popover>
</template>
<script>
import Vue from 'vue'
import { computed, defineComponent } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import ProjectSwitcherItem from '@/components/ProjectSwitcherItem.vue'
import Button from '@/components/Button.vue'
import CheckMemberRole from '@/components/CheckMemberRole.vue'

export default defineComponent({
  name: 'ProjectSwitcherPortal',
  components: {
    ProjectSwitcherItem,
    Button,
    CheckMemberRole,
  },
  props: {
    darkTheme: {
      type: Boolean,
      default: false,
    },
    target: {
      type: [Object, Function, String, HTMLElement, SVGElement],
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    currentProject: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    showClient: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { Project } = Vue.$FeathersVuex.api

    const params = computed(() => ({
      query: {
        organization: props.organization._id,
        archived: { $ne: true },
        $limit: 10,
        $sort: { updatedAt: -1 },
      },
    }))

    const { items: projects } = useFind({
      model: Project,
      params,
    })

    const hidePopover = () => {
      context.emit('update:show', false)
    }

    const goToProject = (navigate, e) => {
      navigate(e)
      hidePopover()
    }

    const newProject = () => {
      context.emit('new-project')
      hidePopover()
    }

    const select = project => {
      context.emit('select', project)
      hidePopover()
    }

    const goToAllProjects = () => {
      context.emit('all-projects')
      hidePopover()
    }

    return {
      projects,
      hidePopover,
      goToProject,
      newProject,
      select,
      goToAllProjects,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .projects-popover {
    @apply -mt-16
  }


  /** common */
  .projects-popover::v-deep .popover-header {
    @apply rounded-t-lg;
  }

  /** light theme */
  .projects-popover:not(.dark)::v-deep .popover-header {
    @apply bg-green-600 border-green-600;
  }

  .bs-popover-right.projects-popover:not(.dark)::v-deep > .arrow::after {
    @apply border-r-green-600;
  }

  /** dark theme */
  .projects-popover.dark {
    @apply bg-darkGray-1000;
  }

  .projects-popover::v-deep .popover-header {
    @apply border-b-0;
  }

  .projects-popover.dark::v-deep .popover-header {
    @apply bg-darkGray-700;
  }

  .bs-popover-right.projects-popover.dark::v-deep > .arrow::after {
    @apply border-r-darkGray-700;
  }
</style>
