
<div v-if="!isPending">
  <TasksPlaceholder
    v-if="!sections.length"
    readonly-sections
    @create-section="createSection"
  />
  <FeatureLimit
    v-else
    v-slot="{ checkFeatureLimit: checkActionItemsLimit }"
    flag="limitActionItems"
    label="action items"
    @allow="createTask"
  >
    <TaskBoard
      :selected-task-id="$route.params.taskId"
      :sections="sections"
      lock-tasks-to-section
      readonly-sections
      no-section-pin-to-dashboard
      no-task-reordering
      no-section-menu
      @update-section="updateSection"
      @remove-section="removeSection"
      @reorder-sections="reorderSections"
      @section-menu-action="handleSectionMenuAction"
      @create-task="checkActionItemsLimit"
      @update-task="updateTask"
      @remove-task="removeTask"
      @move-task="moveTask"
    >
      <template #task="{ section, task }">
        <router-link
          v-slot="{ navigate }"
          :to="{
            name: PORTAL_TASKS_LIST_TASK,
            params: {
              sectionId: section.taskSection._id,
              taskId: task._id
            }
          }"
          custom
        >
          <ProjectTaskListItem
            :task="task"
            hide-drag-handle
            hide-visibility-badge
            :hide-delete-button="readonlyTask(task)"
            @update="updateTask"
            @remove="removeTask(section, task)"
            @click="navigate"
          />
        </router-link>
      </template>
    </TaskBoard>
  </FeatureLimit>
  <router-view
    :project="project"
    @remove="task => removeTask(null, task)"
    @created="goToTasks"
    @updated="goToTasks"
  />
</div>
