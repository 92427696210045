
<div v-if="activeOrganization" class="client-portal">
  <SpinnerOverlay v-if="isLoading" />
  <Configuration v-else v-slot="{ isPending: isConfigurationPending }">
    <router-view
      v-if="!isConfigurationPending"
      :domain="domain"
      :user="user"
      :organization="organization"
      :client="client"
      :projects="projects"
      :preview="isPortalPreview"
    />
  </Configuration>
</div>
<LayoutPortalGuest v-else>
  <Error
    variant="warning"
    title="Unavailable"
  >
    <div>
      <h3>
        This portal is currently unavailable.
      </h3>
      <h4 class="text-14">Please contact {{ organization.name }} </h4>
    </div>
  </Error>
</LayoutPortalGuest>
