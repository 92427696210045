// Tasks
import ViewClientPortalTasks from '@/views/ViewClientPortalTasks.vue'
import ViewClientPortalTasksList from '@/views/ViewClientPortalTasksList.vue'
import ViewClientPortalTasksKanban from '@/views/ViewClientPortalTasksKanban.vue'
import ViewClientPortalTask from '@/views/ViewClientPortalTask.vue'
import ViewClientPortalTasksDocuments from '@/views/ViewClientPortalTasksDocuments.vue'

import {
  PORTAL_TASKS,
  PORTAL_TASKS_LIST,
  PORTAL_TASKS_LIST_TASK,
  PORTAL_TASKS_KANBAN,
  PORTAL_TASKS_KANBAN_TASK,
  PORTAL_TASKS_DOCUMENTS,
  PORTAL_TASKS_DOCUMENTS_TASK,
} from './types'

export default [
  {
    name: PORTAL_TASKS,
    path: 'tasks',
    component: ViewClientPortalTasks,
    props: true,
    redirect: { name: PORTAL_TASKS_LIST },
    children: [
      {
        name: PORTAL_TASKS_LIST,
        path: 'list',
        component: ViewClientPortalTasksList,
        children: [
          {
            name: PORTAL_TASKS_LIST_TASK,
            path: ':sectionId/:taskId',
            component: ViewClientPortalTask,
            props: ({ params }) => ({ ...params, mode: 'list' }),
          },
        ],
      },
      {
        name: PORTAL_TASKS_KANBAN,
        path: 'board',
        component: ViewClientPortalTasksKanban,
        children: [
          {
            name: PORTAL_TASKS_KANBAN_TASK,
            path: ':sectionId/:taskId',
            component: ViewClientPortalTask,
            props: ({ params }) => ({ ...params, mode: 'board' }),
          },
        ],
      },
      {
        name: PORTAL_TASKS_DOCUMENTS,
        path: 'documents',
        component: ViewClientPortalTasksDocuments,
        children: [
          {
            name: PORTAL_TASKS_DOCUMENTS_TASK,
            path: ':documentId/:taskId',
            component: ViewClientPortalTask,
            props: ({ params }) => ({ ...params, mode: 'documents' }),
          },
        ],
      },
    ],
  },
]
