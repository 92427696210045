<template>
  <div class="portal-paragraph-font">
    <RecoverPasswordFormRequest
      v-if="!isSuccess"
      button-class="portal-button"
      @success="isSuccess = true"
    />
    <div v-else class="flex flex-col items-center justify-center text-center">
      <span class="w-48 text-green-600 icon_v2-so_tick text-48"></span>
      <h2 class="title-h2 my-16">{{ $t('login.great') }}!</h2>
      <p class="text-gray-700">
        {{ $t('login.password-recover-link-sent') }}
      </p>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import RecoverPasswordFormRequest from '@/components/RecoverPasswordFormRequest.vue'

export default defineComponent({
  name: 'ViewClientPortalPasswordRequest',
  components: {
    RecoverPasswordFormRequest,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isSuccess = ref(false)

    return {
      isSuccess,
    }
  },
})
</script>
