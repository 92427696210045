<template>
  <component
    :is="component"
    v-bind="$props"
    v-on="$listeners"
  />
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import ClientPortalFSFolder from '@/components/ClientPortalFSFolder.vue'
import ClientPortalFSFolderPublic from '@/components/ClientPortalFSFolderPublic.vue'

const componentMap = {
  [DOCUMENT_CATEGORY.Folder]: ClientPortalFSFolder,
  [DOCUMENT_CATEGORY.ProjectPublicFolder]: ClientPortalFSFolderPublic,
};

export default defineComponent({
  name: 'ViewFSFolder',
  components: {
    ClientPortalFSFolder,
    ClientPortalFSFolderPublic,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const component = computed(() => componentMap[props.document.category]);

    return {
      component,
    }
  },
})
</script>
