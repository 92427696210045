<template>
  <div>
    <PageHeader :title="$t('settings.language-select')" />
    <div class="max-w-form">
      <SelectLanguage />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import PageHeader from '@/components/PageHeader.vue'
import SelectLanguage from '@/components/SelectLanguage.vue'

export default defineComponent({
  name: 'ViewUserSettingsLanguage',
  components: {
    PageHeader,
    SelectLanguage,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
})
</script>
