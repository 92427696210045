
<ProjectTheme
  :project="project"
  no-dark-mode
  :wrapper-class="project?.portalTheme === 'dark' ? 'bg-darkGray-1000':'bg-page'"
>
  <DocActions
    v-slot="{
      download,
    }"
    :document="document"
  >
    <DocRenderer
      :document="document"
      page-header-show-back
      @page-header-back="$router.go(-1)"
    >
      <template #breadcrumbs>
        <DocLibraryBreadcrumbs
          :document="document"
          :category="document.category"
          is-portal
        />
      </template>
      <template #actions>
        <DocMenu
          :document="document"
          :active-items="{
            download: true,
          }"
          @download="download"
        />
      </template>
      <template #header>
        <DocTitle :document="document" disabled />
      </template>

      <template #footer-left>
        <DocUpdatedBy v-if="!$isMobile" :document="document" />
        <DocSidebarMobile
          v-if="$isMobile"
          :document="document"
          :organization-id="document.organization"
          :active-items="sidebarActiveItems"
        />
      </template>
      <template #content>
        <DocumentViewerClientPortal
          :document="document"
          :organization="organization"
          is-client-portal
          :project="project"
        />
        <DocApprovalBadge :document="document" />
      </template>
    </DocRenderer>
  </DocActions>
</ProjectTheme>
