<template>
  <div class="flex items-center justify-between w-full">
    <ProjectSwitcherItem :project="project" class="w-[190px]" />
    <template v-if="!$isMobile">
      <ButtonIcon
        id="project-switch-button"
        v-b-tooltip.hover
        icon="icon_v2-so_switch text-24 hover:dark:text-darkGray-300"
        :title="$t('common.projects')"
        @click="showProjectSwitchPopover = !showProjectSwitchPopover"
      />
      <ProjectSwitcherPortal
        target="project-switch-button"
        :organization="organization"
        :current-project="project"
        :show.sync="showProjectSwitchPopover"
        :dark-theme="project.isDarkTheme"
        @select="goToProject"
        @all-projects="goToAllProjects"
      />
    </template>
    <Button
      v-else
      variant="transparent"
      class="text-gray-700 border border-gray-400 bg-white pr-4 items-center flex"
      @click="goToAllProjects"
    >
      Projects
      <span class="icon icon_v2-so_project-solid text-16 h-16" />
    </Button>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { PORTAL_PROJECT, PORTAL_PROJECTS } from '@/client-portal/router/types'
import ProjectSwitcherPortal from '@/components/ProjectSwitcherPortal.vue'
import ProjectSwitcherItem from '@/components/ProjectSwitcherItem.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'ProjectNavPortalHeader',
  components: {
    ProjectSwitcherPortal,
    ProjectSwitcherItem,
    ButtonIcon,
    Button,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    noProjectSwitcher: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { routerPush } = useRouter()
    const showProjectSwitchPopover = ref(false)

    const goToProject = ({ _id: projectId }) => {
      if (projectId === props.project._id) {
        // current project
        return
      }

      routerPush({
        name: PORTAL_PROJECT,
        params: {
          projectId,
        },
      })
    }

    const goToAllProjects = () => {
      routerPush({
        name: PORTAL_PROJECTS,
      })
    }


    return {
      showProjectSwitchPopover,
      goToProject,
      goToAllProjects,
    }
  },
})
</script>
