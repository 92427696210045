
<ProjectTheme
  :project="project"
  :wrapper-class="
    project?.portalTheme === 'dark'
      ? 'bg-darkGray-1000'
      : 'bg-page'
  "
>
  <ProjectPermissions
    v-slot="{ permissions }"
    :project="project"
  >
    <DocRenderer :document="document">
      <template #breadcrumbs>
        <DocBreadcrumbs :document="document" :project="project" />
      </template>
      <template #actions>
        <DocFolderViewOptions
          :value="viewContext"
          @input="value => $emit('update-view-context', value)"
        />

        <DocCreateButton v-if="permissions.create" />
      </template>
      <template #header>
        <DocTitleReadonly :project="project" />
      </template>

      <template #content>
        <div v-if="document" v-fragment>
          <FolderContentList
            :document="document"
            :view-context="viewContext"
            :permissions="permissions"
          />
          <RecentDocuments
            v-if="recentlyUpdatedModule.enabled"
            :project="project"
            hide-private
          />
        </div>
      </template>
    </DocRenderer>
  </ProjectPermissions>
</ProjectTheme>
