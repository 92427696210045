
<b-form-select :value="$root.$i18n.locale" @change="changeLang">
  <b-form-select-option
    v-for="(lang, i) in locales"
    :key="`lang-${i}`"
    :value="lang"
  >
    {{ localesNames[lang] }}
  </b-form-select-option>
</b-form-select>
