<template>
  <div>
    <PageHeader :title="linkedApp.title" />
    <ProjectEmbeddedApp :app="linkedApp" />
  </div>
</template>

<script>
import { propEq } from 'ramda'
import { defineComponent, computed } from '@vue/composition-api'
import PageHeader from '@/components/PageHeader.vue'
import ProjectEmbeddedApp from '@/components/ProjectEmbeddedApp.vue'

export default defineComponent({
  name: 'ViewClientPortalEmbeddedApp',
  components: {
    PageHeader,
    ProjectEmbeddedApp,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    linkedAppId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const linkedApp = computed(
      () => props.project.portalLinkedApps.find(propEq('_id', props.linkedAppId))
    )

    return {
      linkedApp,
    }
  },
})
</script>

<style scoped lang="scss">
.iframely-responsive {
    top: 0; left: 0; width: 100%; height: 0;
    position: relative;
    padding-bottom: 56%;
}
.iframely-responsive>* {
    top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;
}

.iframely-embed {
  @apply mx-auto flex-1;
  max-width: none!important;
}

.iframely-wrapper {
  // @apply mx-auto;
}

.iframely-wrapper>* {
  @apply mx-auto;
}
</style>
