<template>
  <ProjectTheme
    :project="project"
    no-dark-mode
    :wrapper-class="project?.portalTheme === 'dark' ? 'bg-darkGray-1000':'bg-page'"
  >
    <DocActions
      v-slot="{
        download,
      }"
      :document="document"
    >
      <DocRenderer
        :document="document"
        page-header-show-back
        @page-header-back="$router.go(-1)"
      >
        <template #breadcrumbs>
          <DocLibraryBreadcrumbs
            :document="document"
            :category="document.category"
            is-portal
          />
        </template>
        <template #actions>
          <DocMenu
            :document="document"
            :active-items="{
              download: true,
            }"
            @download="download"
          />
        </template>
        <template #header>
          <DocTitle :document="document" disabled />
        </template>

        <template #footer-left>
          <DocUpdatedBy v-if="!$isMobile" :document="document" />
          <DocSidebarMobile
            v-if="$isMobile"
            :document="document"
            :organization-id="document.organization"
            :active-items="sidebarActiveItems"
          />
        </template>
        <template #content>
          <DocumentViewerClientPortal
            :document="document"
            :organization="organization"
            is-client-portal
            :project="project"
          />
          <DocApprovalBadge :document="document" />
        </template>
      </DocRenderer>
    </DocActions>
  </ProjectTheme>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { STATUS as DOCUMENT_STATUS } from '@/v2/services/documents/documentsTypes'
import DocumentViewerClientPortal from '@/components/Document/Viewer/DocumentViewerClientPortal.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import DocApprovalBadge from '@/components/Doc/DocApprovalBadge.vue'
import DocLibraryBreadcrumbs from '@/components/Doc/DocLibraryBreadcrumbs.vue'
import DocMenu from '@/components/Doc/DocMenu.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitle from '@/components/Doc/DocTitle.vue'
import DocUpdatedBy from '@/components/Doc/DocUpdatedBy.vue'
import DocActions from '@/components/Doc/DocActions.vue'
import DocSidebarMobile from '@/components/Doc/DocSidebarMobile.vue'

export default defineComponent({
  name: 'ClientPortalLibraryDocument',
  components: {
    DocumentViewerClientPortal,
    ProjectTheme,
    DocApprovalBadge,
    DocLibraryBreadcrumbs,
    DocMenu,
    DocRenderer,
    DocTitle,
    DocUpdatedBy,
    DocActions,
    DocSidebarMobile,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      DOCUMENT_STATUS,
    }
  },
})
</script>
