
<nav>
  <SidebarGroup>
    <SidebarMenu>
      <SidebarMenuLink
        :to="{ name: PORTAL_USER_SETTINGS_LANGUAGE }"
      >
        <SidebarItemBasic
          :label="$t('settings.language-select')"
          icon="icon_v2-so_globe"
        />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: PORTAL_USER_SETTINGS_PROFILE }"
      >
        <SidebarItemBasic
          :label="$t('settings.my-account')"
          icon="icon_v2-so_user-circle"
        />
      </SidebarMenuLink>
      <SidebarMenuLink
        v-if="showAuthentication"
        :to="{ name: PORTAL_USER_SETTINGS_SECURITY }"
      >
        <SidebarItemBasic
          :label="$t('settings.authentication')"
          icon="icon_v2-so_lock"
        />
      </SidebarMenuLink>
      <SidebarMenuLink
        :to="{ name: PORTAL_USER_SETTINGS_NOTIFICATIONS }"
      >
        <SidebarItemBasic
          :label="$t('settings.notifications')"
          icon="icon_v2-so_notification-ringing"
        />
      </SidebarMenuLink>
    </SidebarMenu>
  </SidebarGroup>
</nav>
