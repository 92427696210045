<template>
  <ButtonTransparent
    v-if="$isMobile && document.isApproved"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <b-badge variant="primary">{{ $t('common.approved') }}</b-badge>
  </ButtonTransparent>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ButtonTransparent from '@/components/ButtonTransparent.vue'

export default defineComponent({
  components: {
    ButtonTransparent,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
})
</script>
