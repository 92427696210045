<template>
  <nav>
    <SidebarGroup>
      <SidebarMenu>
        <SidebarMenuLink
          :to="{ name: PORTAL_USER_SETTINGS_LANGUAGE }"
        >
          <SidebarItemBasic
            :label="$t('settings.language-select')"
            icon="icon_v2-so_globe"
          />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: PORTAL_USER_SETTINGS_PROFILE }"
        >
          <SidebarItemBasic
            :label="$t('settings.my-account')"
            icon="icon_v2-so_user-circle"
          />
        </SidebarMenuLink>
        <SidebarMenuLink
          v-if="showAuthentication"
          :to="{ name: PORTAL_USER_SETTINGS_SECURITY }"
        >
          <SidebarItemBasic
            :label="$t('settings.authentication')"
            icon="icon_v2-so_lock"
          />
        </SidebarMenuLink>
        <SidebarMenuLink
          :to="{ name: PORTAL_USER_SETTINGS_NOTIFICATIONS }"
        >
          <SidebarItemBasic
            :label="$t('settings.notifications')"
            icon="icon_v2-so_notification-ringing"
          />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
  </nav>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  PORTAL_USER_SETTINGS_LANGUAGE,
  PORTAL_USER_SETTINGS_NOTIFICATIONS,
  PORTAL_USER_SETTINGS_PROFILE,
  PORTAL_USER_SETTINGS_SECURITY,
} from '@/client-portal/router/types'
import SidebarGroup from '@/components/Sidebar/SidebarGroup.vue'
import SidebarMenu from '@/components/Sidebar/SidebarMenu.vue'
import SidebarMenuLink from '@/components/Sidebar/SidebarMenuLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'

export default defineComponent({
  name: 'UserSettingsNavPortal',
  components: {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuLink,
    SidebarItemBasic,
  },
  props: {
    showAuthentication: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      PORTAL_USER_SETTINGS_LANGUAGE,
      PORTAL_USER_SETTINGS_NOTIFICATIONS,
      PORTAL_USER_SETTINGS_PROFILE,
      PORTAL_USER_SETTINGS_SECURITY,
    }
  },
})
</script>
