<template>
  <div v-if="document" v-fragment>
    <!-- ------------------------------------------------ -->
    <!-- --------- MOBILE - NAVIGATION ------------------ -->
    <!-- ------------------------------------------------ -->
    <nav class="w-full">
      <div>
        <!-- Tabs -->
        <div
          class="
            flex items-center justify-between
            print:hidden overflow-hidden
            h-64
            so-sticky-footer-bar transition-all
          "
        >
          <b-button
            v-if="activeItemsIndexed[docSidebarTypes.CONTENTS] && !isEmptyToc"
            variant="slim"
            class="mobile-tab-bar-button"
            :class="
              documentSidebarStatus.tab === docSidebarTypes.CONTENTS
                && 'mobile-tab-bar-button-active'
            "
            @click.stop="setTab(docSidebarTypes.CONTENTS)"
          >
            <span class="relative block h-20">
              <span class="icon_v2-so_list-view mobile-tab-bar-button-icon"></span>
            </span>
            <span class="mobile-tab-bar-button-label capitalize">
              Contents
            </span>
          </b-button>
          <!-- Inbox Toggle Button -->
          <FeathersCount
            v-if="activeItemsIndexed[docSidebarTypes.NOTIFICATIONS]"
            :key="`inbox-counter-${document._id}`"
            v-slot="{ count: notificationsCount }"
            :query="{ document: document._id, read: false }"
            model="Notification"
            :events="['created', 'patched', 'markAllAsRead']"
          >
            <b-button
              variant="slim"
              class="mobile-tab-bar-button"
              :class="
                documentSidebarStatus.tab === docSidebarTypes.NOTIFICATIONS
                  && 'mobile-tab-bar-button-active'
              "
              @click.stop="setTab(docSidebarTypes.NOTIFICATIONS)"
            >
              <span class="relative block h-20">
                <span
                  class="icon_v2-so_inbox mobile-tab-bar-button-icon"
                ></span>
                <span
                  v-if="notificationsCount"
                  class="
                    bg-orange-600 text-white text-12 font-bold leading-none rounded-md
                    items-center absolute -top-8 h-18 px-4 z-10
                    select-none pointer-events-none sidebar-counter
                    -right-8
                  "
                >{{ notificationsCount }}</span>
              </span>
              <span class="mobile-tab-bar-button-label">Activity</span>
            </b-button>
          </FeathersCount>

          <!-- Action Items Toggle Button -->
          <FeathersCount
            v-if="activeItemsIndexed[docSidebarTypes.ACTION_ITEMS]"
            :key="`actionItems-counter-${document._id}`"
            v-slot="{ count: actionItemsCount }"
            :query="{ document: document._id, isResolved: false }"
            model="ActionItem"
            :events="['created', 'patched', 'removed']"
          >
            <b-button
              variant="slim"
              class="mobile-tab-bar-button"
              :class="
                documentSidebarStatus.tab === docSidebarTypes.ACTION_ITEMS
                  && 'mobile-tab-bar-button-active'
              "
              @click.stop="setTab(docSidebarTypes.ACTION_ITEMS)"
            >
              <span class="relative block h-20">
                <span
                  class="icon_v2-so_action-items mobile-tab-bar-button-icon"
                ></span>
                <span
                  v-if="actionItemsCount"
                  class="
                    bg-orange-600 text-white text-12 font-bold leading-none rounded-md
                    items-center absolute -top-8 h-18 px-4 z-10
                    select-none pointer-events-none sidebar-counter
                    -right-8
                  "
                >{{ actionItemsCount }}</span>
              </span>
              <span class="mobile-tab-bar-button-label">
                {{ projectModules.tasks.title || $t('modules.action-items') }}
              </span>
            </b-button>
          </FeathersCount>

          <!-- Comments toggle button -->
          <FeathersCount
            v-if="activeItemsIndexed[docSidebarTypes.COMMENTS]"
            :key="`comments-counter-${document._id}`"
            v-slot="{ count: commentsCount }"
            :query="{
              document: document._id,
              isResolved: false,
            }"
            model="Comment"
          >
            <b-button
              variant="slim"
              class="mobile-tab-bar-button"
              :class="
                documentSidebarStatus.tab === docSidebarTypes.COMMENTS
                  && 'mobile-tab-bar-button-active'
              "
              @click.stop="setTab(docSidebarTypes.COMMENTS)"
            >
              <span class="relative block h-20">
                <span class="icon_v2-so_chat mobile-tab-bar-button-icon"></span>
                <span
                  v-if="commentsCount"
                  class="
                    bg-orange-600 text-white text-12 font-bold leading-none rounded-md
                    items-center absolute -top-8 h-18 px-4 z-10
                    select-none pointer-events-none sidebar-counter
                    -right-8
                  "
                >{{ commentsCount }}</span>
              </span>
              <span class="mobile-tab-bar-button-label capitalize">
                {{ $t('common.comments') }}
              </span>
            </b-button>
          </FeathersCount>

          <!-- Analytics toggle button -->

          <b-button
            v-if="activeItemsIndexed[docSidebarTypes.ANALYTICS]"
            variant="slim"
            class="mobile-tab-bar-button"
            :class="
              documentSidebarStatus.tab === docSidebarTypes.ANALYTICS
                && 'mobile-tab-bar-button-active'
            "
            @click.stop="setTab(docSidebarTypes.ANALYTICS)"
          >
            <span class="relative block h-20">
              <span class="icon_v2-so_bar-chart mobile-tab-bar-button-icon"></span>
              <span
                v-if="analyticsCount"
                class="
                  bg-gray-500 text-white text-12 font-bold leading-none rounded-md
                  items-center absolute -top-8 h-18 px-4 z-10
                  select-none pointer-events-none sidebar-counter
                  -right-8
                "
              >{{ analyticsCount }}</span>
            </span>
            <span class="mobile-tab-bar-button-label capitalize">
              Analytics
            </span>
          </b-button>
        </div>
      </div>
    </nav>

    <b-sidebar
      id="sidebar-right"
      title="Sidebar"
      no-header
      right
      :bg-variant="project?.portalTheme === 'dark' ? 'dark' : 'light'"
      no-slide
      width="100vw"
      :visible="Boolean(documentSidebarStatus.tab)"
      sidebar-class="h-[calc(100vh_-_95px)] z-1060"
      body-class="
        absolute inset-x-16 top-8 flex flex-col
        b-sidebar-actions-comments dark:bg-darkGray-1000
        rounded-t-lg  border border-gray-300 dark:border-darkGray-600 page-background
      "
    >
      <template #default>
        <div class="relative px-16">
          <div class="flex items-center justify-between flex-1 py-16">
            <div
              class="flex items-center h-32"
              :class="isClientPortal && !isPublicLink && 'pl-32'"
            >
              <h3 class="font-semibold text-gray-700 dark:text-darkGray-400 text-18">
                <span v-if="documentSidebarStatus.tab === docSidebarTypes.ACTION_ITEMS">
                  {{ projectModules.tasks.title || $t('modules.action-items') }}
                </span>
                <span v-else>{{ documentSidebarStatus.title }}</span>
              </h3>
            </div>
            <button
              class="text-darkGray-800 dark:text-darkGray-300"
              @click="setTab(documentSidebarStatus.tab)"
            >
              <span class="relative w-24 text-24 icon_v2-so_close" />
            </button>
          </div>
        </div>

        <!-- ------------------------------------------------------------ -->
        <!-- --------- MOBILE - TABS - CONTENT TOC ------------- -->
        <!-- ------------------------------------------------------------ -->
        <div
          v-if="documentSidebarStatus.tab === docSidebarTypes.CONTENTS"
          class="flex-1 px-16 overflow-y-auto pt-16"
        >
          <DocumentViewerToc
            variant="mobile"
            :document="document"
          />
        </div>

        <!-- ------------------------------------------------------------ -->
        <!-- --------- MOBILE - TABS - CONTENT ACTION ITEMS ------------- -->
        <!-- ------------------------------------------------------------ -->
        <div
          v-if="documentSidebarStatus.tab === docSidebarTypes.ACTION_ITEMS"
          class="flex-1 px-16 overflow-y-auto pt-16"
        >
          <div
            v-if="!document.sharingClientEnabled && !isClientPortal"
            class="
              p-8
              mb-12
              leading-none
              bg-blue-100
              rounded-md
              text-13 text-black/70
            "
          >
            <span class="icon_v2-so_lock text-12"></span>
            <strong class="ml-4">Private document.</strong>
            <br />Action items will not be visible to the client.
          </div>

          <ActionItems
            :is-viewer="isClientPortal"
            :display-private="!isClientPortal"
            :document="document"
            :project="project"
            :organization-id="organizationId"
            :node-id="documentSidebarStatus.actionItemNodeId"
            class-name="block"
            @scroll-to-node="nodeId => scrollToNode({ _id: nodeId })"
          />
        </div>

        <!-- ------------------------------------------------------------ -->
        <!-- --------- MOBILE - TABS - CONTENT COMMENTS ------------- -->
        <!-- ------------------------------------------------------------ -->
        <div
          v-if="documentSidebarStatus.tab === docSidebarTypes.COMMENTS"
          class="relative w-full px-16 flex-1 max-h-[calc(100%_-_56px)]"
        >
          <!-- COMMENTS -->
          <CommentsSidebar
            :document-id="document?._id"
            :is-toggled="true"
            :node-id="documentSidebarStatus.commentNodeId"
            @scroll-to-node="nodeId => scrollToNode({ _id: nodeId })"
            @close="setTab(docSidebarTypes.COMMENTS)"
          />
        </div>

        <!-- ------------------------------------------------------------ -->
        <!-- --------- MOBILE - TABS - CONTENT ANALYTICS ------------- -->
        <!-- ------------------------------------------------------------ -->
        <div
          v-if="
            documentSidebarStatus.tab === docSidebarTypes.ANALYTICS &&
              !isClientPortal
          "
          class="relative w-full px-16 h-full pt-16"
        >
          <DocumentAnalyticsList :document-id="document._id" />
        </div>

        <!-- ------------------------------------------------------------ -->
        <!-- --------- MOBILE - TABS - CONTENT INBOX ------------- -->
        <!-- ------------------------------------------------------------ -->
        <div
          v-if="
            documentSidebarStatus.tab === docSidebarTypes.NOTIFICATIONS &&
              !isClientPortal
          "
          class="relative w-full px-16 h-full pt-16"
        >
          <DocumentActivity :document="document._id" />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { defineComponent, computed, inject } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { docSidebarTypes } from '@/types/document-sidebar-tabs'
import { CATEGORY as CONTENT_BLOCK_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { useProjectModules } from '@/v2/services/projects/compositions'
import useDocumentNav from '@/v2/lib/composition/useDocumentNav'
import EventBus from '@/event-bus/event-bus'
import FeathersCount from '@/components/FeathersCount.vue'
import ActionItems from '@/components/ActionItems.vue'
import CommentsSidebar from '@/components/CommentsSidebar.vue'
import DocumentAnalyticsList from '@/components/DocumentAnalyticsList.vue'
import DocumentViewerToc from '@/components/Document/Viewer/DocumentViewerToc.vue'
import DocumentActivity from '@/components/DocumentActivity.vue'

const allItems = Object.values(docSidebarTypes)

const SCROLL_OFFSET = -128
const { useState: useStateDocumentEditor, useActions } = createNamespacedHelpers('documentEditor')

export default defineComponent({
  name: 'DocSidebarMobile',
  components: {
    FeathersCount,
    ActionItems,
    CommentsSidebar,
    DocumentAnalyticsList,
    DocumentViewerToc,
    DocumentActivity,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    organizationId: {
      type: String,
      required: true,
    },
    isPublicLink: {
      type: Boolean,
      default: false,
    },
    activeItems: {
      type: Array,
      default: null, // if null, all items are active
    },
  },
  setup(props, context) {
    const isClientPortal = inject('isClientPortal', true)
    const activeItemsIndexed = computed(() => {
      const _activeItems = props.activeItems ?? allItems
      return _activeItems.reduce((acc, item) => ({ ...acc, [item]: true }), {})
    })

    const {
      documentSidebarStatus,
    } = useStateDocumentEditor([
      'documentSidebarStatus',
    ])

    const { toggleDocumentSidebar } = useActions(['toggleDocumentSidebar'])
    const { Project } = context.root.$FeathersVuex.api

    const project = computed(() => {
      if (props.document) return Project.getFromStore(props.document?.project)
      return null
    })

    const projectModules = useProjectModules(project)

    const organization = useCurrentOrganization()

    const setTab = tab => {
      toggleDocumentSidebar(tab)
    }

    // TOC
    const { nodes: tocNodes } = useDocumentNav(props.document?._id, [
      CONTENT_BLOCK_CATEGORY.ElementHeading,
      CONTENT_BLOCK_CATEGORY.ElementQA,
    ])

    const isEmptyToc = computed(() => Boolean(!tocNodes.value.length))

    // SCROLL TO NODE
    const scrollToNode = node => {
      if (node) {
        // scrollToElementId(`node-${node._id}`, SCROLL_OFFSET)
        EventBus.$emit(
          'scrollToElementId:appScrollContainer',
          `node-${node._id}`,
          SCROLL_OFFSET
        )
      }
    }

    const analyticsCount = computed(
      () => (props.document?.viewCount ?? 0)
        + (props.document?.countQAAnswered ?? 0)
    )

    return {
      docSidebarTypes,
      setTab,
      documentSidebarStatus,
      project,
      analyticsCount,
      organization,
      // TOC
      isEmptyToc,
      tocNodes,
      scrollToNode,
      activeItemsIndexed,
      isClientPortal,

      projectModules,
    }
  },
})
</script>


<style lang="scss" scoped>
.so-sticky-footer-bar {
  padding-bottom: calc(env(safe-area-inset-bottom));
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
}
</style>
