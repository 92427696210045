import LayoutCenter from '@/views/LayoutCenter.vue'
import LayoutClientPortalGuest from '@/views/LayoutClientPortalGuest.vue'
import ViewClientPortalAuth from '@/views/ViewClientPortalAuth.vue'
import ViewClientPortalAuthenticated from '@/views/ViewClientPortalAuthenticated.vue'
import ViewClientPortalLogin from '@/views/ViewClientPortalLogin.vue'
import ViewErrorNotFound from '@/views/ViewErrorNotFound.vue'
import ViewClientPortalPasswordRequest from '@/views/ViewClientPortalPasswordRequest.vue'
import ViewClientPortalPasswordReset from '@/views/ViewClientPortalPasswordReset.vue'
import ViewClientPortalPasswordSet from '@/views/ViewClientPortalPasswordSet.vue'

import { GUEST_EMBEDDED_DOCUMENT, GUEST_SHARED_DOCUMENT } from '@/router/guest/type'
import { NOT_FOUND } from '@/router/error/type'
import { reqCtxNs, mapParamsToProps } from '@/v2/lib/hooks/requestContextForServer'
import {
  PORTAL_AUTH,
  PORTAL_LOGIN,
  PORTAL_RECOVER_PASSWORD_REQUEST,
  PORTAL_RECOVER_PASSWORD_RESET,
  PORTAL_PASSWORD_SET,
} from './types'
import userRoutes from './userRoutes'


/** @type {Array<import('vue-router').RouteConfig>} */
const routes = [
  {
    path: '/',
    component: LayoutClientPortalGuest,
    meta: {
      requiresGuest: true,
    },
    children: [
      {
        name: PORTAL_LOGIN,
        path: '/login',
        component: ViewClientPortalLogin,
        props: true,
      },
      {
        name: PORTAL_RECOVER_PASSWORD_REQUEST,
        path: 'recover-password',
        component: ViewClientPortalPasswordRequest,
        meta: {
          noRedirect: true,
        },
      },
      {
        name: PORTAL_RECOVER_PASSWORD_RESET,
        path: `recover-password/:${reqCtxNs('token')}`,
        component: ViewClientPortalPasswordReset,
        props: mapParamsToProps,
        meta: {
          noRedirect: true,
        },
      },
    ],
  },
  {
    path: '/error',
    component: LayoutCenter,
    children: [
      {
        name: NOT_FOUND,
        path: 'not-found',
        component: ViewErrorNotFound,
      },
    ],
  },
  {
    path: '/auth/:token',
    name: PORTAL_AUTH,
    component: ViewClientPortalAuth,
    props: true,
  },

  // ----- Guest document view -----
  {
    path: `/share/:${reqCtxNs('token')}`,
    component: () => import('@/views/ViewPublicLink.vue'),
    props: mapParamsToProps,
    meta: {
      intercomDisable: true,
    },
    children: [
      {
        path: '',
        name: GUEST_SHARED_DOCUMENT,
        component: () => import('@/views/ViewPublicLinkDocument.vue'),
      },
      {
        name: GUEST_EMBEDDED_DOCUMENT,
        path: ':documentId',
        component: () => import('@/views/ViewPublicLinkDocument.vue'),
        props: true,
      },
    ],
  },

  // authenticated users
  {
    name: PORTAL_PASSWORD_SET,
    path: '/set-password',
    component: ViewClientPortalPasswordSet,
    props: true,
    meta: {
      intercomDisable: true,
      requiresAuth: true,
      noRedirect: true,
    },
  },
  {
    path: '/',
    component: ViewClientPortalAuthenticated,
    props: true,
    children: userRoutes,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
