
<div class="grid">
  <ClientPortalTasksPageHeader
    :view-context="viewContext"
    :keyword.sync="keyword"
    :hidden-tabs="hiddenTabs"
    :hide-new-section-button="$route.name === PORTAL_TASKS_DOCUMENTS"
    @update:view-context="updateViewContext"
  />
  <router-view
    v-if="viewContext"
    :organization="organization"
    :project="project"
    :view-context="viewContext"
    :keyword="keyword"
  />
</div>
