import RouterView from '@/components/RouterView.vue'
import ViewClientPortalProject from '@/views/ViewClientPortalProject.vue'
import ViewClientPortalProjects from '@/views/ViewClientPortalProjects.vue'
import ViewClientPortalDashboard from '@/views/ViewClientPortalDashboard.vue'
import ViewClientPortalEmbeddedApp from '@/views/ViewClientPortalEmbeddedApp.vue'
import ViewClientPortalUpdates from '@/views/ViewClientPortalUpdates.vue'
import ViewClientPortalDocument from '@/views/ViewClientPortalDocument.vue'
import ViewPage from '@/views/ViewPage.vue'

// FileSystem
import ViewClientPortalFS from '@/views/ViewClientPortalFS.vue'
import ViewClientPortalFSFolder from '@/views/ViewClientPortalFSFolder.vue'
import ViewClientPortalFSDocument from '@/views/ViewClientPortalFSDocument.vue'

import {
  PORTAL_PROJECTS,
  PORTAL_PROJECT,
  PORTAL_DASHBOARD,
  PORTAL_UPDATES,
  PORTAL_PACKAGED_SERVICES,
  PORTAL_LINKED_APP,
  PORTAL_FS_FOLDER,
  PORTAL_FS_DOCUMENT,
  PORTAL_PINNED_FS_FOLDER,
  PORTAL_PINNED_FS_DOCUMENT,
  PORTAL_PACKAGED_SERVICES_LIST,
  PORTAL_PACKAGED_SERVICES_DOCUMENT,
  PORTAL_PAGE,
} from './types'

import userSettings from './userSettingsRoutes'
import taskRoutes from './tasksRoutes'

export default [
  {
    name: PORTAL_PROJECTS,
    path: '/projects',
    component: ViewClientPortalProjects,
    props: true,
    meta: {
      intercomDisable: true,
      noRedirect: true,
    },
  },
  {
    path: '/p/:projectId',
    name: PORTAL_PROJECT,
    component: ViewClientPortalProject,
    props: true,
    redirect: { name: PORTAL_DASHBOARD },
    children: [
      {
        path: '',
        name: PORTAL_DASHBOARD,
        component: ViewClientPortalDashboard,
        props: true,
      },
      // FileSystem
      {
        path: 'fs/folders/:folderId',
        component: ViewClientPortalFS,
        props: true,
        children: [
          {
            name: PORTAL_FS_FOLDER,
            path: '',
            component: ViewClientPortalFSFolder,
            props: true,
          },
          {
            name: PORTAL_FS_DOCUMENT,
            path: 'docs/:documentId',
            component: ViewClientPortalFSDocument,
            props: true,
          },
        ],
      },
      // Pinned folders
      {
        path: 'fs/pinned/:folderId',
        component: ViewClientPortalFS,
        props: route => ({ ...route.params, isPinnedFolder: true }),
        children: [
          {
            name: PORTAL_PINNED_FS_FOLDER,
            path: '',
            component: ViewClientPortalFSFolder,
            props: true,
          },
          {
            name: PORTAL_PINNED_FS_DOCUMENT,
            path: 'docs/:documentId',
            component: ViewClientPortalFSDocument,
            props: true,
          },
        ],
      },
      {
        path: 'updates',
        name: PORTAL_UPDATES,
        component: ViewClientPortalUpdates,
        props: true,
      },
      {
        path: 'packaged-services',
        name: PORTAL_PACKAGED_SERVICES,
        component: RouterView,
        // redirect: { name: PORTAL_PACKAGED_SERVICES_LIST },
        children: [
          // {
          //   path: '',
          //   name: PORTAL_PACKAGED_SERVICES_LIST,
          //   component: ViewClientPortalPackagedServices,
          //   props: true,
          // },
          {
            path: ':documentId',
            name: PORTAL_PACKAGED_SERVICES_DOCUMENT,
            component: ViewClientPortalDocument,
            props: true,
          },
        ],
      },
      {
        path: 'pages/:documentId',
        name: PORTAL_PAGE,
        component: ViewPage,
        props: true,
      },
      {
        path: 'app/:linkedAppId',
        name: PORTAL_LINKED_APP,
        component: ViewClientPortalEmbeddedApp,
        props: true,
      },
      ...taskRoutes,
    ],
  },
  ...userSettings,
];
