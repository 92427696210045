<template>
  <ProjectTheme
    :project="project"
    :wrapper-class="
      project?.portalTheme === 'dark'
        ? 'bg-darkGray-1000'
        : 'bg-page'
    "
  >
    <ProjectPermissions
      v-slot="{ permissions }"
      :project="project"
    >
      <DocActions
        v-slot="{
          update,
          remove,
          move,
          download,
          approve,
        }"
        :document="document"
      >
        <DocRenderer :document="document">
          <template #breadcrumbs>
            <DocBreadcrumbs :document="document" :project="project" />
          </template>
          <template #actions>
            <DocFolderViewOptions
              :value="viewContext"
              @input="value => $emit('update-view-context', value)"
            />
            <DocCreateButton
              v-if="permissions.create"
              :disabled="document.status === DOCUMENT_STATUS.APPROVED"
              disabled-reason="Approved folders are read-only"
            />
            <DocMenu
              :document="document"
              :active-items="{
                remove: permissions.remove,
                download: true,
                move: permissions.create && permissions.remove,
                approval: $isMobile,
              }"
              @remove="remove"
              @move="move"
              @download="download"
              @approve="approve"
            />
          </template>
          <template #header>
            <DocTitle
              :disabled="!permissions.update"
              :document="document"
              @update="title => update({ title })"
            />
            <DocApprovedBadge :document="document" />
          </template>

          <template #footer-left>
            <DocUpdatedBy v-if="!$isMobile" :document="document" />
            <DocSidebarMobile
              v-if="$isMobile"
              :document="document"
              :organization-id="document.organization"
              :active-items="sidebarActiveItems"
            />
          </template>
          <template v-if="!$isMobile" #footer-right>
            <DocStatusToggle
              :value="document.status"
              data-intercom-target="Document Status Select"
              @input="status => update({ status })"
            />
          </template>

          <template #content>
            <FolderContentList
              v-if="document"
              :document="document"
              :view-context="viewContext"
              :permissions="permissions"
            />
            <DocApprovalBadge :document="document" />
          </template>
          <template #sidebar-right>
            <DocSidebar
              :document="document"
              :active-items="sidebarActiveItems"
              no-task-reordering
            />
          </template>
        </DocRenderer>
      </DocActions>
    </ProjectPermissions>
  </ProjectTheme>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { STATUS as DOCUMENT_STATUS } from '@/types/document'
import { docSidebarTypes } from '@/types/document-sidebar-tabs'
import FolderContentList from '@/components/FolderContentList.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import DocApprovalBadge from '@/components/Doc/DocApprovalBadge.vue'
import DocApprovedBadge from '@/components/Doc/DocApprovedBadge.vue'
import DocBreadcrumbs from '@/components/Doc/DocBreadcrumbs.vue'
import DocCreateButton from '@/components/Doc/DocCreateButton.vue'
import DocFolderViewOptions from '@/components/Doc/DocFolderViewOptions.vue'
import DocMenu from '@/components/Doc/DocMenu.vue'
import DocRenderer from '@/components/Doc/DocRenderer.vue'
import DocTitle from '@/components/Doc/DocTitle.vue'
import DocUpdatedBy from '@/components/Doc/DocUpdatedBy.vue'
import DocStatusToggle from '@/components/Doc/DocStatusToggle.vue'
import DocActions from '@/components/Doc/DocActions.vue'
import DocSidebar from '@/components/Doc/DocSidebar.vue'
import DocSidebarMobile from '@/components/Doc/DocSidebarMobile.vue'
import ProjectPermissions from '@/components/ProjectPermissions.vue'

const SIDEBAR_ACTIVE_ITEMS = [
  docSidebarTypes.NOTIFICATIONS,
  docSidebarTypes.ACTION_ITEMS,
  docSidebarTypes.COMMENTS,
]

export default defineComponent({
  name: 'ClientPortalFSFolder',
  components: {
    FolderContentList,
    ProjectTheme,
    DocApprovalBadge,
    DocApprovedBadge,
    DocBreadcrumbs,
    DocCreateButton,
    DocFolderViewOptions,
    DocMenu,
    DocRenderer,
    DocTitle,
    DocUpdatedBy,
    DocStatusToggle,
    DocActions,
    DocSidebar,
    DocSidebarMobile,
    ProjectPermissions,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      DOCUMENT_STATUS,
      sidebarActiveItems: SIDEBAR_ACTIVE_ITEMS,
    }
  },
})
</script>
