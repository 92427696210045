<template>
  <ClientPortalPasswordCreateGuard :organization="organization">
    <LayoutApp
      :dark-theme="project?.isDarkTheme"
      mobile-sticky-footer
    >
      <template #header>
        <MainHeader
          :organization="organization"
          :client="client"
          :dark-theme="project?.isDarkTheme"
          no-switch
        >
          <template v-if="!$isMobile" #nav>
            <PageHeaderSearch
              v-if="project"
              v-model="keyword"
              :placeholder="$t('project-search.placeholder-header')"
              :show-result="showSearchResult"
              @close="closeSearchResult"
            >
              <ProjectDashboardPageHeaderSearch :keyword="keyword" />
            </PageHeaderSearch>
          </template>
          <template #user>
            <DropdownUserPortal :organization="organization" />
          </template>
        </MainHeader>
      </template>
      <template #main>
        <router-view
          :organization="organization"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </template>
    </LayoutApp>
  </ClientPortalPasswordCreateGuard>
</template>
<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import { useCurrentProject } from '@/v2/services/projects/compositions'
import LayoutApp from '@/views/LayoutApp.vue'
import ClientPortalPasswordCreateGuard from '@/components/ClientPortalPasswordCreateGuard.vue'
import MainHeader from '@/components/MainHeader.vue'
import DropdownUserPortal from '@/components/DropdownUserPortal.vue'
import PageHeaderSearch from '@/components/PageHeaderSearch.vue'
import ProjectDashboardPageHeaderSearch from '@/components/ProjectDashboardPageHeaderSearch.vue'

export default defineComponent({
  name: 'ViewClientPortalAuthenticated',
  components: {
    LayoutApp,
    ClientPortalPasswordCreateGuard,
    MainHeader,
    DropdownUserPortal,
    PageHeaderSearch,
    ProjectDashboardPageHeaderSearch,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const project = useCurrentProject()
    const keyword = ref('')
    const showSearchResult = ref(false)

    const closeSearchResult = () => {
      keyword.value = ''
      showSearchResult.value = false
    }

    watch(keyword, newVal => {
      showSearchResult.value = Boolean(newVal?.length)
    })

    return {
      project,
      keyword,
      showSearchResult,
      closeSearchResult,
    }
  },
})
</script>
