
<div class="flex items-center justify-between w-full">
  <ProjectSwitcherItem :project="project" class="w-[190px]" />
  <template v-if="!$isMobile">
    <ButtonIcon
      id="project-switch-button"
      v-b-tooltip.hover
      icon="icon_v2-so_switch text-24 hover:dark:text-darkGray-300"
      :title="$t('common.projects')"
      @click="showProjectSwitchPopover = !showProjectSwitchPopover"
    />
    <ProjectSwitcherPortal
      target="project-switch-button"
      :organization="organization"
      :current-project="project"
      :show.sync="showProjectSwitchPopover"
      :dark-theme="project.isDarkTheme"
      @select="goToProject"
      @all-projects="goToAllProjects"
    />
  </template>
  <Button
    v-else
    variant="transparent"
    class="text-gray-700 border border-gray-400 bg-white pr-4 items-center flex"
    @click="goToAllProjects"
  >
    Projects
    <span class="icon icon_v2-so_project-solid text-16 h-16" />
  </Button>
</div>
