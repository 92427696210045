
<WithSidebar>
  <template #sidebar-header>
    <SidebarBack @back="goToLastProject">
      {{ $t('settings.back-to-project') }}
    </SidebarBack>
  </template>
  <template #sidebar>
    <UserSettingsNavPortal
      :show-authentication="organization.portalLoginPasswordEnabled"
    />
  </template>
  <template #default>
    <router-view
      :user="user"
      :organization="organization"
    />
  </template>
</WithSidebar>
