<template>
  <LayoutClientPortalGuest :organization="organization" :domain="{}">
    <h1 class="project-page-subtitle mb-32">
      {{ $t('login.password-set-required') }}
    </h1>
    <UserSettingsPassword
      label-password="Password"
      label-confirm-password="Confirm Password"
      @success="onSuccess"
    >
      <template #buttons>
        <Button
          class="mt-40 portal-button"
          size="md"
          type="submit"
          variant="primary"
        >
          {{ $t('login.password-save') }}
        </Button>
      </template>
    </UserSettingsPassword>
  </LayoutClientPortalGuest>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { PORTAL_PROJECTS } from '@/client-portal/router/types'
import { useRouter } from '@/v2/lib/composition/useRouter'
import LayoutClientPortalGuest from '@/views/LayoutClientPortalGuest.vue'
import UserSettingsPassword from '@/components/UserSettingsPassword.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'ViewClientPortalPasswordSet',
  components: {
    UserSettingsPassword,
    Button,
    LayoutClientPortalGuest,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { routerReplace } = useRouter()
    const onSuccess = () => {
      routerReplace({ name: PORTAL_PROJECTS })
    }

    return {
      PORTAL_PROJECTS,
      onSuccess,
    }
  },
})
</script>
