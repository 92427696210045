<template>
  <div>
    <PageHeader :title="timelineModule.title || $t('modules.timeline')" />
    <ProjectTimeline :project-id="project._id" :page-size="25" />
  </div>
  </ProjectPage>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import { useProjectModule } from '@/v2/services/projects/compositions';
import PageHeader from '@/components/PageHeader.vue'
import ProjectTimeline from '@/components/ProjectTimeline/ProjectTimeline.vue'

export default defineComponent({
  name: 'ViewClientPortalUpdates',
  components: {
    PageHeader,
    ProjectTimeline,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const timelineModule = useProjectModule(computed(() => props.project), 'timeline')

    return {
      timelineModule,
    }
  },
})
</script>
