<template>
  <ConnectionStatus>
    <Domain v-if="routeReady" v-slot="{ domain, error }">
      <LayoutPortalGuest v-if="error">
        <Error
          variant="danger"
          title="404"
          error="404 not found"
        >
          <div>This page is not available</div>
        </Error>
      </LayoutPortalGuest>
      <div v-else class="h-screen">
        <Announcement />
        <ClientPortalAppController :domain="domain" />
      </div>
    </Domain>
  </ConnectionStatus>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { useRoute } from '@/v2/lib/composition/useRouter'
import ConnectionStatus from '@/components/ConnectionStatus.vue'
import LayoutPortalGuest from '@/views/LayoutPortalGuest.vue'
import Error from '@/components/Error.vue'
import ClientPortalAppController from '@/components/ClientPortalAppController.vue'
import Domain from '@/components/Domain.vue'
import Announcement from '@/components/Announcement.vue'

export default defineComponent({
  name: 'ClientPortalApp',
  components: {
    LayoutPortalGuest,
    Error,
    Domain,
    ClientPortalAppController,
    ConnectionStatus,
    Announcement,
  },
  setup() {
    const { routeReady } = useRoute({ watchRouteReady: true })

    return {
      routeReady,
    }
  },
})
</script>
