
<div>
  <InfoBox
    :title="$t('tasks.fromDocsAndFilesTitle')"
    icon="icon_v2-so_docs-files"
  >
    {{ $t('tasks.fromDocsAndFilesInfo') }}
  </InfoBox>
  <FeatureLimit
    v-slot="{ checkFeatureLimit: checkActionItemsLimit }"
    flag="limitActionItems"
    label="action items"
    @allow="createTask"
  >
    <TaskBoard
      :selected-task-id="$route.params.taskId"
      :sections="sections"
      readonly-sections
      lock-tasks-to-section
      no-section-pin-to-dashboard
      no-task-reordering
      no-section-menu
      @section-menu-action="handleSectionMenuAction"
      @create-task="checkActionItemsLimit"
      @update-task="updateTask"
      @remove-task="removeTask"
      @move-task="moveTask"
    >
      <template #section-title="{ section }">
        <ProjectTasksDocumentTitle :document="section.document" />
      </template>
      <template #task="{ section, task }">
        <router-link
          v-slot="{ navigate }"
          :to="{
            name: PORTAL_TASKS_DOCUMENTS_TASK,
            params: {
              documentId: section.document._id,
              taskId: task._id
            }
          }"
          custom
        >
          <ProjectTaskListItem
            :task="task"
            hide-drag-handle
            hide-visibility-badge
            :hide-delete-button="readonlyTask(task)"
            @update="updateTask"
            @remove="removeTask(section, task)"
            @click="navigate"
          />
        </router-link>
      </template>
    </TaskBoard>
  </FeatureLimit>
  <router-view
    :project="project"
    @remove="task => removeTask(null, task)"
    @created="goToTasks"
    @updated="goToTasks"
  />
</div>
