
<ConnectionStatus>
  <Domain v-if="routeReady" v-slot="{ domain, error }">
    <LayoutPortalGuest v-if="error">
      <Error
        variant="danger"
        title="404"
        error="404 not found"
      >
        <div>This page is not available</div>
      </Error>
    </LayoutPortalGuest>
    <div v-else class="h-screen">
      <Announcement />
      <ClientPortalAppController :domain="domain" />
    </div>
  </Domain>
</ConnectionStatus>
