import ViewClientPortalUserSettings from '@/views/ViewClientPortalUserSettings.vue'
import ViewUserSettingsProfile from '@/views/user-settings/ViewUserSettingsProfile.vue'
import ViewUserSettingsSecurity from '@/views/user-settings/ViewUserSettingsSecurity.vue'
import ViewUserSettingsNotificationsPortal from '@/views/user-settings/ViewUserSettingsNotificationsPortal.vue'
import ViewUserSettingsLanguage from '@/views/user-settings/ViewUserSettingsLanguage.vue'

import {
  PORTAL_USER_SETTINGS,
  PORTAL_USER_SETTINGS_PROFILE,
  PORTAL_USER_SETTINGS_SECURITY,
  PORTAL_USER_SETTINGS_NOTIFICATIONS,
  PORTAL_USER_SETTINGS_LANGUAGE,
} from './types'

export default [
  {
    name: PORTAL_USER_SETTINGS,
    path: 'user-settings',
    component: ViewClientPortalUserSettings,
    redirect: {
      name: PORTAL_USER_SETTINGS_PROFILE,
    },
    children: [
      {
        name: PORTAL_USER_SETTINGS_PROFILE,
        path: 'profile',
        component: ViewUserSettingsProfile,
        meta: {
          noRedirect: true,
        },
      },
      {
        name: PORTAL_USER_SETTINGS_SECURITY,
        path: 'security',
        component: ViewUserSettingsSecurity,
        meta: {
          noRedirect: true,
        },
      },
      {
        name: PORTAL_USER_SETTINGS_NOTIFICATIONS,
        path: 'notifications',
        component: ViewUserSettingsNotificationsPortal,
        meta: {
          noRedirect: true,
        },
      },
      {
        name: PORTAL_USER_SETTINGS_LANGUAGE,
        path: 'language',
        component: ViewUserSettingsLanguage,
        meta: {
          noRedirect: true,
        },
      },
    ],
  },
]
