<template>
  <WithSidebar>
    <template #sidebar-header>
      <SidebarBack @back="goToLastProject">
        {{ $t('settings.back-to-project') }}
      </SidebarBack>
    </template>
    <template #sidebar>
      <UserSettingsNavPortal
        :show-authentication="organization.portalLoginPasswordEnabled"
      />
    </template>
    <template #default>
      <router-view
        :user="user"
        :organization="organization"
      />
    </template>
  </WithSidebar>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { PORTAL_PROJECT } from '@/client-portal/router/types'
import { useRouter } from '@/v2/lib/composition/useRouter'
import UserSettingsNavPortal from '@/components/UserSettingsNavPortal.vue'
import WithSidebar from '@/components/WithSidebar.vue'
import SidebarBack from '@/components/Sidebar/SidebarBack.vue'

export default defineComponent({
  name: 'ViewClientPortalUserSettings',
  components: {
    UserSettingsNavPortal,
    WithSidebar,
    SidebarBack,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { routerPush } = useRouter()

    const goToLastProject = () => {
      let lastProjectId

      try {
        lastProjectId = JSON.parse(localStorage.getItem('clientPortalLastProjectId'))
      } catch (err) {
        lastProjectId = null
      }

      if (lastProjectId) {
        routerPush({
          name: PORTAL_PROJECT,
          params: { projectId: lastProjectId },
          // query: { preview: queryPreview.value },
        })
      }
    }

    return {
      goToLastProject,
    }
  },
})
</script>
