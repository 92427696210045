
<ProjectTheme
  :project="project"
  no-dark-mode
  :wrapper-class="project?.portalTheme === 'dark' ? 'bg-darkGray-1000':'bg-page'"
>
  <ProjectPermissions
    v-slot="{ permissions }"
    :project="project"
  >
    <DocActions
      v-slot="{
        update,
        remove,
        move,
        download,
        approve,
      }"
      :document="document"
    >
      <DocRenderer :document="document">
        <template #breadcrumbs>
          <DocBreadcrumbs :document="document" :project="project" />
        </template>
        <template #actions>
          <DocMenu
            :document="document"
            :active-items="{
              remove: permissions.remove,
              download: true,
              move: permissions.create && permissions.remove,
              approval: $isMobile,
            }"
            @remove="remove"
            @move="move"
            @download="download"
            @approve="approve"
          />
        </template>
        <template #header>
          <DocTitle
            :disabled="!permissions.update"
            :document="document"
            @update="title => update({ title })"
          />
          <DocApprovedBadge :document="document" />
        </template>

        <template #footer-left>
          <DocUpdatedBy v-if="!$isMobile" :document="document" />
          <DocSidebarMobile
            v-if="$isMobile"
            :document="document"
            :organization-id="document.organization"
            :active-items="sidebarActiveItems"
          />
        </template>
        <template v-if="!$isMobile" #footer-right>
          <DocStatusToggle
            :value="document.status"
            @input="status => update({ status })"
          />
        </template>

        <template #content>
          <FileContent
            v-if="document"
            :document="document"
          />
          <DocApprovalBadge :document="document" />
        </template>
        <template #sidebar-right>
          <DocSidebar
            :document="document"
            :active-items="sidebarActiveItems"
            no-task-reordering
          />
        </template>
      </DocRenderer>
    </DocActions>
  </ProjectPermissions>
</ProjectTheme>
