
<div class="-mx-16 -mt-16">
  <ProjectDashboard
    :organization="organization"
    :project="project"
    :routes="routes"
    :action-items="actionItemsSorted"
    :is-portal-preview="preview"
  />
</div>
